import constant from 'lodash/fp/constant';
import { makeFetchAction } from 'redux-api-call';

// Notice:
// This is also used in src/state/middlewares/dismissRequestAfterSignOutMiddleware.js
export const LOGOUT_USER_API_CALL_TYPE = 'LOGOUT_USER';

export const userSignOutEndPoint = '/users/sign_out';

const { actionCreator: logOutCurrentUser } = makeFetchAction(
  LOGOUT_USER_API_CALL_TYPE,
  constant({
    endpoint: userSignOutEndPoint,
    method: 'DELETE',
    headers: {
      accept: 'application/json',
    },
  })
);

export { logOutCurrentUser };
