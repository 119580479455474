export const enum VerificationStatusType {
  PENDING = 'pending',
  IN_PROGRESS = 'in_progress',
  VERIFIED = 'verified',
  FAILED = 'failed',
}

export type VerificationStatus = {
  data: {
    hiring_essential: VerificationStatusType;
    hiring_essential_domain: VerificationStatusType;
  };
};
