// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'hoot... Remove this comment to see the full error message
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
import { deepUpdate } from 'hoothoot';
import get from 'lodash/fp/get';
import keys from 'lodash/fp/keys';
import reduce from 'lodash/fp/reduce';

import captureError from '../eh-utils/captureError';
import { SCOPES, RouteConfigs } from './types';

export { SCOPES } from './types';

type RouteConfig = RouteConfigs[0];
type RouteConfigRoute = RouteConfig['route'];

const updateFunctionCreator =
  (newData: RouteConfigRoute = {}) =>
  (oldData: RouteConfigRoute = {}) => {
    const newDataKey: string = get('[0]')(keys(newData));
    if (oldData[newDataKey]) {
      captureError('routing duplicated', { dataKey: newDataKey });
      return {
        ...oldData,
        ...newData,
      };
    }
    return {
      ...oldData,
      ...newData,
    };
  };

export const registerRoutes =
  (routes: RouteConfigs = []) =>
  (routeConfig: RouteConfigRoute = {}) =>
    reduce((config: RouteConfigRoute, route: RouteConfig) => {
      if (route.scope === SCOPES.UNIVERSAL) {
        return updateFunctionCreator(route.route)(config);
      }
      if (route.scope === SCOPES.USER) {
        return deepUpdate(
          'USER.childRoutes',
          updateFunctionCreator(route.route),
          config
        );
      }
      if (route.scope === SCOPES.ORGANISATION) {
        return deepUpdate(
          'ORGANISATION.childRoutes',
          updateFunctionCreator(route.route),
          config
        );
      }
      if (route.scope === SCOPES.MEMBER) {
        return deepUpdate(
          'ORGANISATION.childRoutes.MEMBER.childRoutes',
          updateFunctionCreator(route.route),
          config
        );
      }
      if (route.scope === SCOPES.ADMIN_SPA) {
        return deepUpdate(
          'ADMIN_SPA.childRoutes',
          updateFunctionCreator(route.route),
          config
        );
      }
      if (route.scope === SCOPES.BUSINESS_PORTAL) {
        return deepUpdate(
          'BUSINESS_PORTAL.childRoutes',
          updateFunctionCreator(route.route),
          config
        );
      }
      if (route.scope === SCOPES.PEO_PORTAL) {
        return deepUpdate(
          'PEO_PORTAL.childRoutes',
          updateFunctionCreator(route.route),
          config
        );
      }
      if (route.scope === SCOPES.TERMINATION_PORTAL) {
        return deepUpdate(
          'TERMINATION_PORTAL.childRoutes',
          updateFunctionCreator(route.route),
          config
        );
      }
      throw new Error(`Invalid route scope: ${route.scope}`);
    }, routeConfig)(routes);
