import { combineReducers } from 'redux';
import { createAction, handleAction } from 'redux-actions';
import { createSelector } from 'reselect';
import path from 'lodash/fp/path';
import pathOr from 'lodash/fp/pathOr';

import { extractOrganisationIdFromPath } from 'eh-utils/path';

import makeSetterAction from '../../../../utils/make-setter-action';
import { SAFE_LOG_OUT_ACTION_TYPE } from '../constants';
import { LayoutInfo } from './types/layoutInfo';

const REDUCER_PATH = 'layout';

export type LayoutInfoState = { [REDUCER_PATH]: LayoutInfo };

const layoutSelector: (state: LayoutInfoState) => LayoutInfo =
  path(REDUCER_PATH);

export const userMenuIsOpenSelector = createSelector(
  layoutSelector,
  path('userMenuIsOpen')
);

export const currentOrganisationIdSelector = createSelector(
  layoutSelector,
  layoutInfo =>
    pathOr(extractOrganisationIdFromPath(), 'currentOrganisationId', layoutInfo)
);

export const currentMembershipOnboardingFromSelector = createSelector(
  layoutSelector,
  path('currentMembershipOnboardingFrom')
);

export const isSafeLoggingOutSelector = createSelector(
  layoutSelector,
  path('isSafeLoggingOut')
);

const { reducer: currentSearchQuery, actionCreator: updateSearchQuery } =
  makeSetterAction('layout/UPDATE_SEARCH_QUERY');

const { reducer: currentOrganisationId, actionCreator: updateOrganisationId } =
  makeSetterAction('layout/SET_CURRENT_ORGANISATION_ID');

const {
  reducer: currentMembershipOnboardingFrom,
  actionCreator: updateMembershipOnboardingFrom,
} = makeSetterAction('layout/SET_CURRENT_MEMBERSHIP_ONBOARDING_FROM');

const {
  reducer: currentOrganisationName,
  actionCreator: updateOrganisationName,
} = makeSetterAction('layout/SET_CURRENT_ORGANISATION_NAME');

export const isSafeLoggingOutReducer = handleAction(
  SAFE_LOG_OUT_ACTION_TYPE,
  () => true,
  false
);

export const safeLogOutCurrentUserAC = createAction(SAFE_LOG_OUT_ACTION_TYPE);

export {
  updateSearchQuery,
  updateOrganisationId,
  updateOrganisationName,
  updateMembershipOnboardingFrom,
};

export const reducer = combineReducers({
  currentSearchQuery,
  currentOrganisationId,
  currentOrganisationName,
  currentMembershipOnboardingFrom,
  isSafeLoggingOut: isSafeLoggingOutReducer,
});

export const branch = { [REDUCER_PATH]: reducer };
