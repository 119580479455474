import path from 'lodash/fp/path';
import { makeFetchAction } from 'redux-api-call';
import { createSelector } from 'reselect';

import { getMainAppHost } from 'eh-utils/getFrontendEnv';

import { currentOrganisationIdSelector } from './layoutInfo';
import { SidebarPermission } from './types/sidebarPermission';

export const FETCH_SIDEBAR_PERMISSIONS_API_CALL_TYPE =
  'layout/FETCH_SIDEBAR_PERMISSIONS';

const {
  actionCreator: fetchSidebarPermissions,
  dataSelector,
  isFetchingSelector: isFetchingPermissionSelector,
  updater: updateSidebarPermissionsAC,
} = makeFetchAction<SidebarPermission, { ignoreCache: boolean } | undefined>(
  FETCH_SIDEBAR_PERMISSIONS_API_CALL_TYPE,
  params => ({
    ignoreCache: params?.ignoreCache,
    endpoint: createSelector(
      currentOrganisationIdSelector,
      id => `${getMainAppHost()}/api/v1/organisations/${id}/sidebar_permissions`
    ),
  }),
  {
    sidebarPermissions: path('permissions'),
    sidebarData: path('data'),
  }
);

export {
  isFetchingPermissionSelector,
  fetchSidebarPermissions,
  updateSidebarPermissionsAC,
};

export const sidebarFullDataSelector = dataSelector;

export const sidebarPermissionsSelector = createSelector(
  dataSelector,
  data => data?.permissions
);

// this data only exists in v1 since it not appear in sidebar navigation
export const accountSecurityPermissionSelector = createSelector(
  sidebarPermissionsSelector,
  permissions => permissions?.account_security
);

// this data only exists in v1 since it not appear in sidebar navigation
export const vevoCheckPermissionSelector = createSelector(
  sidebarPermissionsSelector,
  permissions => permissions?.work_eligibility_report
);

export const sidebarDataSelector = createSelector(
  dataSelector,
  data => data?.data
);

// this data only exists in v1 since it not appear in sidebar navigation
export const canUseBulkVevoSelector = createSelector(
  sidebarDataSelector,
  data => data?.work_eligibility_report?.use_vevo
);
// this data only exists in v1 since it not appear in sidebar navigation
export const supplierSettingPermissionSelector = createSelector(
  sidebarDataSelector,
  data => data?.payroll?.supplier_setting
);
// this data only exists in v1 since it not appear in sidebar navigation
export const isAllowedToUsePassportSelector = createSelector(
  sidebarPermissionsSelector,
  permissions => permissions?.passport
);
export const homePathSelector = createSelector(
  currentOrganisationIdSelector,
  currentOrgId => `/app/v2/organisations/${currentOrgId}/dashboard`
);

// this data only exists in v1 since it not appear in sidebar navigation
export const hasAccessToAPISelector = createSelector(
  sidebarPermissionsSelector,
  permissions => permissions?.open_api_developer
);

// this data only exists in v1 since it not appear in sidebar navigation
export const hasAccessToWebhooksSelector = createSelector(
  sidebarPermissionsSelector,
  permissions => permissions?.api_webhook
);

export const hasAccessToDeveloperPortalSelector = createSelector(
  hasAccessToAPISelector,
  hasAccessToWebhooksSelector,
  (accessToAPI, accessToWebhook) => accessToAPI || accessToWebhook
);

// this data only exists in v1 since it not appear in sidebar navigation
export const mandatory2FASelector = createSelector(
  sidebarPermissionsSelector,
  permissions => permissions?.mandatory_2fa
);

// this data only exists in v1 since it not appear in sidebar navigation
export const accessSurveyAdvancedSettingsSelector = createSelector(
  sidebarPermissionsSelector,
  permissions => permissions?.employee_happiness_advanced_settings
);

// this data only exists in v1 since it not appear in sidebar navigation
export const accessJobKeeperBanner = createSelector(
  sidebarDataSelector,
  data => data?.job_keeper?.banner
);

// this data only exists in v1 since it not appear in sidebar navigation
export const accessEmployeeHappinessEnhancedReportSelector = createSelector(
  sidebarPermissionsSelector,
  permissions => permissions?.employee_happiness_enhanced_report
);

// this data only exists in v1 since it not appear in sidebar navigation
export const sandboxRefreshButtonSelector = createSelector(
  sidebarPermissionsSelector,
  permissions => permissions?.sandbox_refresh
);

export const billingV1Selector = createSelector(
  sidebarPermissionsSelector,
  permissions => permissions?.billing
);

// this data only exists in v1 since it not appear in sidebar navigation
export const zuoraEnhancementsSelector = createSelector(
  sidebarDataSelector,
  data => data?.billing?.zuora_enhancements ?? false
);

export const accessGuidedImplementationWizardSelector = createSelector(
  sidebarPermissionsSelector,
  permissions => permissions?.guided_implementation_onboarding
);

export const hrFeaturesSelector = createSelector(
  sidebarPermissionsSelector,
  permissions => permissions?.hr_features
);

export const isSetupCompanyWizardSelector = createSelector(
  sidebarPermissionsSelector,
  permissions => permissions?.company_setup_wizard
);
