// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// TODO: Add type declaration for hoothoot
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'hoot... Remove this comment to see the full error message
import { deepSet, deepGet } from 'hoothoot';
import remove from 'lodash/fp/remove';

import { UpdateFunction } from './types';

export const deleteItem: UpdateFunction = key => deletedItem => origin => {
  const newItemId = deletedItem?.id;
  const path = `data.${key}`;
  const data = deepGet(path)(origin);
  const newData = remove((item: any) => item?.id === newItemId)(data);
  return deepSet(path)(newData)(origin);
};

export const updateItem: UpdateFunction = key => updatedItem => origin => {
  const updatedItemId = updatedItem?.id;
  const path = `data.${key}[{id=${updatedItemId}}]`;
  return deepSet(path)(updatedItem)(origin);
};

export const addItem: UpdateFunction = key => newItem => origin => {
  const path = `data.${key}`;
  const oldData = deepGet(path)(origin);
  const newData = [...oldData, newItem];
  return deepSet(path)(newData)(origin);
};
