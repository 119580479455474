import { ComponentType, FunctionComponent } from 'react';
import { DefaultRootState } from 'react-redux';

export enum SCOPES {
  UNIVERSAL = 'UNIVERSAL',
  ORGANISATION = 'ORGANISATION',
  MEMBER = 'MEMBER',
  ADMIN_SPA = 'ADMIN_SPA',
  BUSINESS_PORTAL = 'BUSINESS_PORTAL',
  PEO_PORTAL = 'PEO_PORTAL',
  USER = 'USER',
  TERMINATION_PORTAL = 'TERMINATION_PORTAL',
}

export enum AUTHORIZATION_TYPES {
  USER_PERMISSION = 'userPermission',
  SIDEBAR_PERMISSION = 'sidebarPermission',
  SAP_PERMISSION = 'sapPermission',
  MANUAL = 'manual',
}

type AuthorizationV1 = {
  type: AUTHORIZATION_TYPES;
  rawPermissionSelector?: (state: DefaultRootState) => unknown;
  at: string;
};

type AuthorizationV2 = {
  scope: SCOPES;
  menu: string;
  subMenu?: string;
  context?: { version?: number; public_holidays?: boolean };
  redirectOnFail?: boolean;
};

export type LeafRoute = {
  path: string;
  title?: string | (() => string);
  exact?: boolean;
  Layout?: ComponentType<any>;
  component: ComponentType<any>;
  authorization?: AuthorizationV1;
  authorizationV2?: AuthorizationV2;
  breadcrumbText?:
    | string
    | FunctionComponent<{ params: Record<string, string> }>;
};

export type RouteWithDefaultComponent = {
  path: string;
  title?: string | (() => string);
  Layout?: ComponentType;
  defaultRouteComponent: ComponentType<any>;
  authorization?: AuthorizationV1;
  authorizationV2?: AuthorizationV2;
  childRoutes: Record<string, RouteShape>;
  breadcrumbText?:
    | string
    | FunctionComponent<{ params: Record<string, string> }>;
};

export type RouteWithoutDefaultComponent = {
  path: string;
  exact?: boolean;
  Layout?: ComponentType<any>;
  authorization?: AuthorizationV1;
  authorizationV2?: AuthorizationV2;
  childRoutes: Record<string, RouteShape>;
  breadcrumbText?:
    | string
    | FunctionComponent<{ params: Record<string, string> }>;
};

export type RouteShape =
  | LeafRoute
  | RouteWithDefaultComponent
  | RouteWithoutDefaultComponent;

export type RouteConfig = Record<string, RouteShape>;

export type RouteConfigs = Array<{
  scope: SCOPES;
  route: Record<string, RouteShape>;
}>;
