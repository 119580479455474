import Loadable from 'eh-utils/Loadable';
import '../styles/index.global';

export const ForgotPassword = Loadable(
  () => import(/* webpackChunkName: 'forgotPassword' */ '../index'),
  'ForgotPassword'
);

export const ResetPasswordForm = Loadable(
  () => import(/* webpackChunkName: 'authen' */ '../index'),
  'ResetPasswordForm'
);

export const ResetPasswordPage = Loadable(
  () => import(/* webpackChunkName: 'resetPassword' */ '../index'),
  'ResetPasswordPage'
);

export const SetupAccount = Loadable(
  () => import(/* webpackChunkName: 'authen' */ '../index'),
  'SetupAccount'
);
