const sendBrowserAgnosticEvent = <T extends HTMLElement | Window>(
  elem: T,
  eventName: string
): Event => {
  // Needed for IE Support: https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/dispatchEvent#Browser_Compatibility
  // https://stackoverflow.com/a/49071358/79677
  // eslint-disable-next-line immutable/no-let
  let event;
  if (typeof Event === 'function') {
    event = new Event(eventName);
  } else {
    event = document.createEvent('Event');
    event.initEvent(eventName, true, true);
  }
  elem.dispatchEvent(event);

  return event;
};

export { sendBrowserAgnosticEvent };
