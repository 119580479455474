import { makeFetchAction } from 'redux-api-call';
import { createSelector } from 'reselect';
import getOr from 'lodash/fp/getOr';

import {
  userFirstNameSelector,
  userEmailSelector as userPassportEmailSelector,
  userSessionTokenSelector as userPassportSessionTokenSelector,
  userDisplayNameSelector,
  userAvatarSelector as userProfileAvatarSelector,
  currentUserProfileSelector,
} from 'eh-session/user';
import {
  formatDisplayName,
  formatPreferredName,
} from 'eh-utils/memberName/format';
import { extractMemberIdFromPath } from 'eh-utils/path';
import { getMainAppHost } from 'eh-utils/getFrontendEnv';

import {
  isOnAdminPortal,
  isOnB2bSupplierPortalLayout,
  isOnBusinessPortalLayout,
  isOnDeveloperPortalLayout,
  isOnGlobalTeamsPortalLayout,
} from '../utils';
import {
  userEmailSelector,
  userInfoFirstNameSelector,
  userInfoFullNameSelector,
  userSessionTokenSelector,
} from '../../../../../shared/apiCalls/userSession/get';
import { currentOrganisationIdSelector } from './layoutInfo';
import { CurrentMembership } from './types/membership';
import { adminSessionTokenSelector } from '../../../../../shared/apiCalls/adminSession/get';

export const API_CALLS_CURRENT_MEMBERSHIP = 'CURRENT_MEMBERSHIP';

// api configs
const {
  actionCreator: fetchCurrentMember,
  updater: updateCurrentMembership,
  isFetchingSelector: isFetchingCurrentMembershipSelector,
  dataSelector: currentMemberSelector,
  errorSelector: errorFetchCurrentMemberSelector,
  headersSelector: currentMemberHeadersSelector,
} = makeFetchAction<
  CurrentMembership,
  { shouldCheckForAdoptionModal?: boolean }
>(API_CALLS_CURRENT_MEMBERSHIP, param => ({
  endpoint: createSelector(
    currentOrganisationIdSelector,
    id => `${getMainAppHost()}/api/v2/organisations/${id}/sessions`
  ),
  metaToHeaderInSuccessResponse: true,
  meta: { shouldCheckForAdoptionModal: param?.shouldCheckForAdoptionModal },
}));

export {
  fetchCurrentMember,
  currentMemberSelector,
  updateCurrentMembership,
  errorFetchCurrentMemberSelector,
  isFetchingCurrentMembershipSelector,
  currentMemberHeadersSelector,
};

export const currentMembershipIdSelector = createSelector(
  currentMemberSelector,
  member => member?.id || extractMemberIdFromPath()
);

export const currentMembershipExternalIdSelector = createSelector(
  currentMemberSelector,
  member => member?.external_id
);

export const peoInfoSelector = createSelector(
  currentMemberSelector,
  member => member?.peo_info
);

export const isPeoMemberInHostOrgSelector = createSelector(
  peoInfoSelector,
  peoInfo => peoInfo?.is_peo_employee_at_host_org
);

export const peoMemberExternalIdSelector = createSelector(
  peoInfoSelector,
  peoInfo => peoInfo?.peo_member_external_id
);

export const peoOrganisationUUIDSelector = createSelector(
  peoInfoSelector,
  peoInfo => peoInfo?.peo_organisation_uuid
);

// Use for Payroll private API
export const currentPayrollEmployeeIdSelector = createSelector(
  isPeoMemberInHostOrgSelector,
  currentMembershipExternalIdSelector,
  peoMemberExternalIdSelector,
  (isPeoMemberInHostOrg, currentMemberExternalId, peoMemberExternalId) =>
    isPeoMemberInHostOrg && peoMemberExternalId
      ? peoMemberExternalId
      : currentMemberExternalId
);

export const currentMembershipUserUUIDSelector = createSelector(
  currentMemberSelector,
  member => member?.user_uuid
);
export const currentMembershipUUIDSelector = createSelector(
  currentMemberSelector,
  member => member?.uuid
);
export const currentPersonalPhoneNumberSelector = createSelector(
  currentMemberSelector,
  member => member?.personal_mobile_number
);
export const currentMemberRoleSelector = createSelector(
  currentMemberSelector,
  member => member?.role
);
export const currentMemberPaymentDetailsAccessSelector = createSelector(
  currentMemberSelector,
  member => member?.payment_details_access
);
export const currentMemberPreferredNameSelector = createSelector(
  currentMemberSelector,
  member =>
    formatPreferredName({
      firstName: member?.first_name || '',
      lastName: member?.last_name || '',
      knownAs: member?.known_as || '',
    })
);
export const currentMemberFirstNameSelector = createSelector(
  currentMemberSelector,
  member => member?.first_name
);
export const currentMemberLastNameSelector = createSelector(
  currentMemberSelector,
  member => member?.last_name
);
export const currentMemberDisplayNameSelector = createSelector(
  currentMemberSelector,
  member =>
    formatDisplayName({
      firstName: member?.first_name || '',
      lastName: member?.last_name || '',
      knownAs: member?.known_as || '',
    })
);
export const currentMemberEmailSelector = createSelector(
  currentMemberSelector,
  member => member?.email
);

export const currentMemberSessionTokenSelector = createSelector(
  currentMemberSelector,
  member => member?.session_token
);

export const currentMemberTimeZoneSelector = createSelector(
  currentMemberSelector,
  member => member?.time_zone
);

export const currentEmailSelector = createSelector(
  userPassportEmailSelector,
  currentMemberEmailSelector,
  userEmailSelector,
  (userPassportEmail, currentMemberEmail, userEmail) => {
    if (userPassportEmail) return userPassportEmail;
    return isOnBusinessPortalLayout() ||
      isOnB2bSupplierPortalLayout() ||
      isOnDeveloperPortalLayout() ||
      isOnGlobalTeamsPortalLayout()
      ? userEmail
      : currentMemberEmail;
  }
);

export const currentUserSessionTokenSelector = createSelector(
  userPassportSessionTokenSelector,
  currentMemberSessionTokenSelector,
  userSessionTokenSelector,
  adminSessionTokenSelector,
  (
    userPassportSessionToken,
    currentMemberSessionToken,
    userSessionToken,
    adminSessionToken
  ) => {
    if (isOnAdminPortal()) return adminSessionToken || userSessionToken;

    if (userPassportSessionToken) return userPassportSessionToken;
    return isOnBusinessPortalLayout() ||
      isOnB2bSupplierPortalLayout() ||
      isOnDeveloperPortalLayout() ||
      isOnGlobalTeamsPortalLayout()
      ? userSessionToken
      : currentMemberSessionToken;
  }
);

export const currentFirstNameSelector = createSelector(
  userFirstNameSelector,
  userInfoFirstNameSelector,
  currentMemberPreferredNameSelector,
  (userFirstName, userInfoFirstName, currentMemberPreferredName) => {
    if (userFirstName) return userFirstName;
    return isOnBusinessPortalLayout() ||
      isOnB2bSupplierPortalLayout() ||
      isOnDeveloperPortalLayout()
      ? userInfoFirstName
      : currentMemberPreferredName;
  }
);

export const currentMemberIndependentContractorSelector = createSelector(
  currentMemberSelector,
  member => member?.independent_contractor
);

export const currentMemberAllowPurchasePowerSelector = createSelector(
  currentMemberSelector,
  member => member?.allow_purchase_power
);

export const currentMemberSandboxSettingIdSelector = createSelector(
  currentMemberSelector,
  member => member?.sandbox_setting_id
);

export const currentMemberAllowBadgeFeatureAccessSelector = createSelector(
  currentMemberSelector,
  member => member?.allow_badge_feature_access
);

export const currentMemberEmploymentTypeSelector = createSelector(
  currentMemberSelector,
  member => member?.employment_type
);

export const currentUserIdSelector = createSelector(
  currentMemberSelector,
  currentUserProfileSelector,
  (member, user) => member?.user_id || user?.user_id
);
export const userNameSelector = createSelector(
  currentMemberSelector,
  member => member?.name
);

export const currentUserNameSelector = createSelector(
  userDisplayNameSelector,
  userInfoFullNameSelector,
  currentMemberDisplayNameSelector,
  (userDisplayName, userInfoFullName, currentMemberDisplayName) => {
    if (userDisplayName) return userDisplayName;
    return isOnBusinessPortalLayout() ||
      isOnB2bSupplierPortalLayout() ||
      isOnDeveloperPortalLayout()
      ? userInfoFullName
      : currentMemberDisplayName;
  }
);

export const userAvatarSelector = createSelector(
  currentMemberSelector,
  member => member?.avatar_url
);
export const currentUserAvatarSelector = createSelector(
  userAvatarSelector,
  userProfileAvatarSelector,
  (userProfileAvatar, memberAvatar) => userProfileAvatar || memberAvatar
);
export const isCurrentUserAdminSelector = createSelector(
  currentMemberSelector,
  member => member?.admin
);
export const isCurrentMemberManagerSelector = createSelector(
  currentMemberSelector,
  member => member?.is_manager
);
export const isCurrentUserBizAccountSelector = createSelector(
  currentMemberSelector,
  member => member?.bussiness_account_personnel
);
export const isB2bSupplierAccountSelector = createSelector(
  currentMemberSelector,
  member => member?.b2b_supplier_account
);
export const currentMemberAcknowledgedTncSelector = createSelector(
  currentMemberSelector,
  member => member?.acknowledged_tnc
);
export const currentMemberFeatureAccessesSelector = createSelector(
  currentMemberSelector,
  member => member?.feature_accesses
);
export const currentMemberWorkCountrySelector = createSelector(
  currentMemberSelector,
  member => member?.work_country
);
export const currentMemberAddressCountrySelector = createSelector(
  currentMemberSelector,
  member => member?.address_country
);

export const membershipApiPrefixSelector = createSelector(
  currentOrganisationIdSelector,
  currentMembershipIdSelector,
  (orgId, memberId) =>
    `${getMainAppHost()}/api/v2/organisations/${orgId}/members/${memberId}`
);

export const legacyMembershipApiPrefixSelector = createSelector(
  currentOrganisationIdSelector,
  currentMembershipIdSelector,
  (orgId, memberId) =>
    `${getMainAppHost()}/api/v1/organisations/${orgId}/members/${memberId}`
);

export const subscriptionAdoptionSelector = createSelector(
  currentMemberSelector,
  member => member?.dashboard_permissions?.subscription_adoption?.permission
);

export const subscriptionAdoptionStandardStepsSelector = createSelector(
  currentMemberSelector,
  member =>
    member?.dashboard_permissions?.subscription_adoption?.meta
      ?.view_standard_steps
);

export const subscriptionAdoptionPremiumStepsSelector = createSelector(
  currentMemberSelector,
  member =>
    member?.dashboard_permissions?.subscription_adoption?.meta
      ?.view_premium_steps
);

export const subscriptionAdoptionPlatinumStepsSelector = createSelector(
  currentMemberSelector,
  member =>
    member?.dashboard_permissions?.subscription_adoption?.meta
      ?.view_platinum_steps
);

export const subscriptionAdoptionSetupStandardStepsSelector = createSelector(
  currentMemberSelector,
  member =>
    member?.dashboard_permissions?.subscription_adoption?.meta
      ?.setup_standard_steps
);

export const subscriptionAdoptionSetupPremiumStepsSelector = createSelector(
  currentMemberSelector,
  member =>
    member?.dashboard_permissions?.subscription_adoption?.meta
      ?.setup_premium_steps
);

export const subscriptionAdoptionSetupPlatinumStepsSelector = createSelector(
  currentMemberSelector,
  member =>
    member?.dashboard_permissions?.subscription_adoption?.meta
      ?.setup_platinum_steps
);

export const badgeFeatureSelector = createSelector(
  currentMemberSelector,
  member => member?.dashboard_permissions?.badge_feature?.permission
);

export const useFeatureGuideAdoptionSelector = createSelector(
  currentMemberSelector,
  member =>
    member?.dashboard_permissions?.subscription_adoption
      ?.use_feature_guide_adoptions
);

export const isGlobalTeamsAdminSelector = createSelector(
  currentMemberSelector,
  member => member?.global_teams_manager
);

export const billingGuideAccessSelector = createSelector(
  currentMemberSelector,
  member => member?.dashboard_permissions?.billing_guide?.permission
);

export const payrollGuidePermissionSelector = createSelector(
  currentMemberSelector,
  member => member?.dashboard_permissions?.payroll_guide?.permission
);

export const payrollPartnerUpdatesSelector = createSelector(
  currentMemberSelector,
  member => member?.payroll_partner_updates
);

export const currentOrdinaryWorkDaySelector = createSelector(
  currentMemberSelector,
  member => member?.typical_work_day
);

export const currentCostCentreIdSelector = createSelector(
  currentMemberSelector,
  member => member?.cost_centre_id
);

export const isUseAdvancedWorkWeekSelector = createSelector(
  currentMemberSelector,
  member => member?.use_advanced_work_week
);

export const canAccessNewSidebarSelector = createSelector(
  currentMemberSelector,
  member => member?.new_nav_bar_access
);

export const isStrictlyEnforceTwoFaSelector = createSelector(
  currentMemberSelector,
  member => member?.strictly_enforce_twofa
);

export const omopPlanAccessSelector = createSelector(
  currentMemberSelector,
  member => member?.omop_plan_access || false
);

export const isIdpMigratedUserSelector = createSelector(
  currentMemberSelector,
  member => member?.idp_migrated
);

export const canAccessCompanyFeedLayoutV2Selector = createSelector(
  currentMemberSelector,
  member => member?.company_feed_layout_v2_access
);

export const businessMdmIdSelector = createSelector(
  currentMemberSelector,
  member => member?.business_mdm_id
);

export const hasCSSForTeamsSelector = createSelector(
  currentMemberSelector,
  member => member?.css_for_teams
);

export const isSystemMemberViewOnlySelector = createSelector(
  currentMemberSelector,
  member => member?.system_member_view_only
);

export const isSystemUserSelector = createSelector(
  currentMemberSelector,
  member => member?.system_user
);

export const terminatedMemberAccessSelector = createSelector(
  currentMemberSelector,
  member => member?.terminated_member_access
);

export const enableReactEmployeeDetailSelector = createSelector(
  currentMemberSelector,
  member => member?.react_employee_detail
);

export const enableEmployeeVisionSelector = createSelector(
  currentMemberSelector,
  member => member?.employee_profile_vision
);

export const isBillingDetailV2EnabledSelector = createSelector(
  currentMemberSelector,
  member => member?.billing_permissions?.billing_details_v2?.permission
);

export const isEmploymentUnlimitedEnabledSelector = createSelector(
  currentMemberSelector,
  member =>
    !!member?.billing_permissions?.employment_unlimited_billing_enabled
      ?.permission
);

export const isEUFlowSelector = createSelector(
  currentMemberSelector,
  member =>
    !!member?.billing_permissions?.employment_unlimited_billing_slice_2_enabled
      ?.permission
);

export const currentCompanyEmailSelector = createSelector(
  currentMemberSelector,
  member => member?.company_email ?? ''
);

export const isUsingHrPrSwagSelector = createSelector(
  currentMemberSelector,
  member => member?.use_hr_pr_swag
);

export const isUsingHrRosteringSitesSlice1Selector = createSelector(
  currentMemberSelector,
  getOr(false, 'use_hr_rostering_sites_slice_1')
);
