import { makeFetchAction } from 'redux-api-call';
import { createSelector } from 'reselect';
import get from 'lodash/fp/get';

// TODO: Get correct path to correct API host
import { getMainAppHost } from 'eh-utils/getFrontendEnv';

// TODO: Add name ( aka action type for fetch )
export const CALL_API_TYPE = 'eh/Admin_Session';

export const genFetchParams = () => ({
  endpoint: `${getMainAppHost()}/sap_api/v1/admin_details`,
  method: 'GET',
});

const {
  actionCreator: fetchAC,
  dataSelector: rawSelector,
  isFetchingSelector,
  updater: rawUpdater,
  // @ts-expect-error TS(2345) FIXME: Argument of type '() => { endpoint: string; method... Remove this comment to see the full error message
} = makeFetchAction(CALL_API_TYPE, genFetchParams);

export { fetchAC, isFetchingSelector, rawUpdater };

export const dataSelector = createSelector(rawSelector, get('data'));

export const adminEmailSelector = createSelector(dataSelector, get('email'));

export const adminUuidSelector = createSelector(dataSelector, get('uuid'));
export const adminSessionTokenSelector = createSelector(
  dataSelector,
  get('session_token')
);

export const featureAccessSelector = createSelector(
  dataSelector,
  get('feature_access_permissions')
);

export const useNewSapAccessPermissionsSelector = createSelector(
  dataSelector,
  get('use_new_sap_access_permissions')
);
