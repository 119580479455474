import constant from 'lodash/fp/constant';
import flow from 'lodash/fp/flow';
import pathOr from 'lodash/fp/pathOr';
import toNumber from 'lodash/fp/toNumber';
import get from 'lodash/fp/get';
import { makeFetchAction } from 'redux-api-call';
import { createSelector } from 'reselect';

import { getMainAppHost } from 'eh-utils/getFrontendEnv';

import { currentOrganisationIdSelector } from './layoutInfo';
import { CurrentOrganisation } from './types/organisation';

export const API_CALLS_CURRENT_ORGANISATION = 'currentOrganisation';

const {
  actionCreator: fetchCurrentOrganisation,
  dataSelector: currentOrganisationSelector,
  isFetchingSelector: isFetchingCurrentOrganisationSelector,
  errorSelector: errorFetchCurrentOrgSelector,
  updater: currentOrganisationDataUpdaterAC,
} = makeFetchAction<CurrentOrganisation>(
  API_CALLS_CURRENT_ORGANISATION,
  constant({
    endpoint: createSelector(
      currentOrganisationIdSelector,
      id => `${getMainAppHost()}/api/v2/organisations/${id}`
    ),
  })
);

export const switchOrganisation = null;

export const currentOrganisationBusinessAccountIdSelector = createSelector(
  currentOrganisationSelector,
  org => org?.business_account_id
);

export const isSEAOrgSelector = createSelector(
  currentOrganisationSelector,
  org => org?.sea_country
);

export const currentOrganisationCobrandingPartnerSelector = createSelector(
  currentOrganisationSelector,
  org => org?.cobranding_partner
);

export const currentOrganisationLogoSelector = createSelector(
  currentOrganisationSelector,
  org => org?.logo_url
);

export const currentOrganisationCountrySelector = createSelector(
  currentOrganisationSelector,
  org => org?.country
);

export const currentOrganisationLocaleSelector = createSelector(
  currentOrganisationSelector,
  org => org?.locale
);

export const currentOrganisationCurrencySelector = createSelector(
  currentOrganisationSelector,
  org => org?.currency
);

export const currentOrganisationUUIDSelector = createSelector(
  currentOrganisationSelector,
  org => org?.uuid
);

export const currentOrganisationPartnerCodeSelector = createSelector(
  currentOrganisationSelector,
  org => org?.partner_code
);

export const currentOrganisationBsbRequiredSelector = createSelector(
  currentOrganisationSelector,
  org => org?.bsb_validation_required
);

export const currentOrganisationCreatedAtSelector = createSelector(
  currentOrganisationSelector,
  org => org?.created_at
);

export const currentOrganisationPrimaryAddressSelector = createSelector(
  currentOrganisationSelector,
  org => org?.primary_address
);

export const currentOrganisationPrimaryAddressV2Selector = createSelector(
  currentOrganisationSelector,
  org => org?.primary_address_v2
);

export const isPeoOrganisationSelector = createSelector(
  currentOrganisationSelector,
  org => org?.is_peo
);

export const currentOrganisationHariConfiguredSelector = createSelector(
  currentOrganisationSelector,
  org => org?.hari_configured
);

export const isCurrentOrganisationIdEvenSelector = createSelector(
  currentOrganisationIdSelector,
  orgId => toNumber(orgId) % 2 === 0
);

export {
  currentOrganisationSelector,
  fetchCurrentOrganisation,
  isFetchingCurrentOrganisationSelector,
  errorFetchCurrentOrgSelector,
  currentOrganisationDataUpdaterAC,
};

export const currentOrganisationSubscriptionPlanSelector = createSelector(
  currentOrganisationSelector,
  org => org?.subscription_plan
);

export const currentOrganisationSubscriptionPlanTierSelector = createSelector(
  currentOrganisationSubscriptionPlanSelector,
  plan => plan?.tier
);

export const currentOrganisationSubscriptionPlanNameSelector = createSelector(
  currentOrganisationSubscriptionPlanSelector,
  plan => plan?.name
);

export const isOmopOrgSelector = createSelector(
  currentOrganisationSubscriptionPlanSelector,
  get('omop')
);

export const currentAppliedSubscriptionPlanSelector = createSelector(
  currentOrganisationSelector,
  org => org?.applied_subscription_plan
);

export const isFreePlanSelector = createSelector(
  currentAppliedSubscriptionPlanSelector,
  data => data?.free
);

export const isSEAFreePlanSelector = createSelector(
  currentAppliedSubscriptionPlanSelector,
  data => data?.external_id === 'sea-free'
);

export const isOriginFreePlanSelector = createSelector(
  currentOrganisationSubscriptionPlanSelector,
  data => data?.free
);

export const currentOrganisationYYSelector = createSelector(
  currentAppliedSubscriptionPlanSelector,
  data => data?.yin_yang
);

export const currentOrganisationOriginYYSelector = createSelector(
  currentOrganisationSubscriptionPlanSelector,
  data => data?.yin_yang
);

export const currentOrganisationSetupModeSelector = createSelector(
  currentOrganisationSelector,
  org => org?.setup_mode
);

export const currentOrganisationPhoneSelector = createSelector(
  currentOrganisationSelector,
  org => org?.phone
);

export const currentOrganisationTimeZoneSelector = createSelector(
  currentOrganisationSelector,
  org => org?.time_zone
);

export const currentOrganisationNameSelector = createSelector(
  (state: any): string | undefined => state?.layout?.currentOrganisationName,
  currentOrganisationSelector,
  (name, org) => name || org?.name
);

export const isSandboxOrganisationSelector = createSelector(
  currentOrganisationSelector,
  org => org?.is_sandbox
);

export const isOrganicOrganisationSelector = createSelector(
  currentOrganisationSelector,
  org => org?.is_organic
);

export const isCSAOrganisationSelector = createSelector(
  currentOrganisationSelector,
  org => org?.is_csa
);

export const currentOrganisationTimesheetEntryTypeSelector = createSelector(
  currentOrganisationSelector,
  org => org?.timesheet_entry_type
);

export const currentOrganisationPayrollSelector = createSelector(
  currentOrganisationSelector,
  org => org?.payroll
);

export const currentOrganisationLimitableInfoSelector = createSelector(
  currentOrganisationSelector,
  org => org?.free_employee_limit_info
);

export const addEmployeeEnableSelector = createSelector(
  currentOrganisationLimitableInfoSelector,
  pathOr(true, 'add_employee_enable')
);

export const approachingFreeLimitSelector = createSelector(
  currentOrganisationLimitableInfoSelector,
  data => data?.approaching_free_employee_limit
);

export const remainingFreeEmployeesSelector = createSelector(
  currentOrganisationLimitableInfoSelector,
  data => data?.remaining_free_employees
);

export const paymentableSelector = createSelector(
  currentOrganisationLimitableInfoSelector,
  data => data?.paymentable
);

export const isOrgFromGenericCountrySelector = createSelector(
  currentOrganisationSelector,
  org => org?.generic_country
);

export const isTimeZoneSetupableSelector = createSelector(
  currentOrganisationSelector,
  org => org?.time_zone_setupable
);

export const trialExpiredDateSelector = createSelector(
  currentOrganisationSelector,
  org => org?.current_trial_opt_in?.expiry_date
);

export const trialPlanTierSelector = createSelector(
  currentOrganisationSelector,
  org => org?.current_trial_opt_in?.subscription_plan_tier
);

export const zuoraBillingPaymentFailedDateSelector = createSelector(
  currentOrganisationSelector,
  org => org?.zuora_subscription?.payment_failed_at
);

export const zuoraBillingStatusSelector = createSelector(
  currentOrganisationSelector,
  org => org?.zuora_subscription?.status
);

export const isAnzPremiumFreeSelector = flow(
  currentOrganisationSelector,
  org => org?.subscription_plan?.external_id === 'anz-premium-free'
);

export const zuoraSubscriptionSelector = createSelector(
  currentOrganisationSelector,
  org => org?.zuora_subscription
);

export const usingGuideImplementationSelector = createSelector(
  currentOrganisationSelector,
  org => org?.current_guided_implementation_feature
);

export const enableEmployingEntityMigrationSelector = createSelector(
  currentOrganisationSelector,
  org => org?.enable_employing_entity_migration
);

export const isDemoOrganisationSelector = createSelector(
  currentOrganisationSelector,
  org => org?.subscription_plan?.external_id === 'demo'
);

export const isATSFreePlanSelector = createSelector(
  currentAppliedSubscriptionPlanSelector,
  data => data?.external_id === 'ats-free'
);

export const gtPartnerCodeSelector = createSelector(
  currentOrganisationSelector,
  org => org?.global_teams_payroll_partner_code
);

export const isSeaNameLocalisationEnabledSelector = createSelector(
  currentOrganisationSelector,
  org => org?.sea_name_localisation_enabled || false
);

export const isMicroBusinessSelector = createSelector(
  currentOrganisationSelector,
  org => org?.is_micro_business || false
);

export const isUkMicroBusinessSelector = createSelector(
  currentOrganisationCountrySelector,
  isMicroBusinessSelector,
  (country, isMicroBusiness) => country === 'GB' && isMicroBusiness
);
