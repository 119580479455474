/* eslint-disable no-underscore-dangle, immutable/no-mutation, immutable/no-this */
type HeroThemeConfigs = {
  fileServiceApiHost?: string;
  defaultEnabledTheme?: 'employment-hero' | 'keypay';
};

type EhHooksConfigs = {
  featureFlagApiHost?: string;
};

export type Configs = HeroThemeConfigs & EhHooksConfigs;

class GlobalEnvConfigsRegistry {
  _configs: {
    [key in keyof Configs]: Configs[key];
  };

  constructor() {
    this._configs = {};
  }

  get registeredConfigs() {
    return {
      // Always return new reducers object
      // to ensure any change on reducers won't affect the binding
      ...this._configs,
    };
  }

  registerConfig(name: keyof Configs, value: Configs[keyof Configs]) {
    this._prependNewConfig(name, value);
  }

  _prependNewConfig(name: keyof Configs, value: Configs[keyof Configs]) {
    this._configs = {
      [name]: value,
      ...this._configs,
    };
  }
}

const globalEnvsRegistry: GlobalEnvConfigsRegistry =
  new GlobalEnvConfigsRegistry();

export { globalEnvsRegistry, GlobalEnvConfigsRegistry };
