import { createStructuredSelector } from 'reselect';

import { currentOrganisationIdSelector } from './layoutInfo';
import {
  currentMembershipIdSelector,
  currentEmailSelector,
} from './currentMembership';

export const inspectletSessionSelector = createStructuredSelector({
  organisationId: currentOrganisationIdSelector,
  memberId: currentMembershipIdSelector,
  email: currentEmailSelector,
});
