import { makeFetchAction } from 'redux-api-call';
import { createSelector } from 'reselect';

import { dataReadyHandler } from 'eh-utils/data';
import { getMainAppHost } from 'eh-utils/getFrontendEnv';

import { currentOrganisationIdSelector } from './layoutInfo';
import {
  VerificationStatus,
  VerificationStatusType,
} from './types/verificationStatus';

export const FETCH_VERIFICATION_STATUS_API_CALL_TYPE =
  'FETCH_VERIFICATION_STATUS';

const {
  actionCreator: fetchVerificationStatusAC,
  dataSelector,
  isFetchingSelector: isFetchingVerificationStatusSelector,
  updater: updateVerificationStatusAC,
  errorSelector: errorVerificationStatusSelector,
} = makeFetchAction<VerificationStatus>(
  FETCH_VERIFICATION_STATUS_API_CALL_TYPE,
  () => ({
    method: 'GET',
    endpoint: createSelector(
      currentOrganisationIdSelector,
      id => `${getMainAppHost()}/api/v3/organisations/${id}/verification_status`
    ),
  })
);

export {
  fetchVerificationStatusAC,
  isFetchingVerificationStatusSelector,
  updateVerificationStatusAC,
  errorVerificationStatusSelector,
};

export const verificationStatusSelector = createSelector(
  dataSelector,
  data => data?.data
);

export const isVerificationStatusDataReadySelector = createSelector(
  isFetchingVerificationStatusSelector,
  dataSelector,
  errorVerificationStatusSelector,
  (isFetching, data, error) => dataReadyHandler(isFetching, data, error)
);

export const unverifiedHiringEssentialSelector = createSelector(
  verificationStatusSelector,
  data =>
    data?.hiring_essential &&
    data.hiring_essential !== VerificationStatusType.VERIFIED
);

export const hiringEssentialVerificationStatusSelector = createSelector(
  verificationStatusSelector,
  data => data?.hiring_essential
);

export const unverifiedHiringEssentialDomainSelector = createSelector(
  verificationStatusSelector,
  data =>
    data?.hiring_essential_domain &&
    data.hiring_essential_domain !== VerificationStatusType.VERIFIED
);
