import ReactDOM from 'react-dom';
import get from 'lodash/fp/get';
import getOr from 'lodash/fp/getOr';
import merge from 'lodash/fp/merge';

import { Intl, addLocaleData } from '@ehrocks/eh-redux-intl';
import enLocaleData from '@ehrocks/eh-redux-intl/locale-data/en';
import { createStore } from 'redux';
import { getNodeEnv } from 'eh-utils/getFrontendEnv';

import { registerRoutes } from 'eh-router';
import './initializer/vitePolyfill';
import './initializer/intlPolyfill';
import './initializer/globalCss';
import './initializer/rwrRegistry';
import './initializer/envsRegistry';
import './initializer';

import initSentry from './packages/eh-utils/sentry';
import legacyRouteConfig from './modules/spa/beta/router/legacyRouteConfig';
import { getLocaleConfigFromCdn } from './state/getLocaleConfig';
import AU_TRANSLATION from './packages/eh-locale/lang/en-AU.json';
import GB_TRANSLATION from './packages/eh-locale/lang/en-GB.json';

const LOCALE_CODE = {
  AU: 'en-AU',
  GB: 'en-GB',
  NZ: 'en-NZ',
};

const rootEntrypoint = document.getElementById('root');

const enhanceRouteConfig = (routeConfig: any) => {
  if (getNodeEnv() === 'development') {
    const extraConfigs = get('ehDevModule.default')(window);
    const routeConfigEnhancer = registerRoutes(extraConfigs);
    return routeConfigEnhancer(routeConfig);
  }
  return routeConfig;
};

const publicEntrypoint = document.getElementById('react-public');

const initApp = () => {
  if (publicEntrypoint) {
    initSentry(get('gon.current_user_email', window));
    import(
      /* webpackChunkName: "publicRoutes" */ './routes/publicRouteConfig'
    ).then(({ default: routeConfig }) => {
      import(/* webpackChunkName: "asyncApp" */ './App').then(
        ({ default: App }) => {
          const newRouteConfig = enhanceRouteConfig(routeConfig);
          ReactDOM.render(
            <App routeConfig={newRouteConfig} showNotiAsDefault />,
            publicEntrypoint
          );
        }
      );
    });
  } else if (rootEntrypoint) {
    initSentry(get('gon.current_user_email', window));
    import(/* webpackChunkName: "routes" */ './routes/routeConfig').then(
      ({ default: routeConfig }) => {
        import(/* webpackChunkName: "asyncApp" */ './App').then(
          ({ default: App }) => {
            const newRouteConfig = enhanceRouteConfig(routeConfig);
            ReactDOM.render(
              <App routeConfig={newRouteConfig} />,
              rootEntrypoint
            );
          }
        );
      }
    );
  } else {
    initSentry(get('gon.current_user_email', window));

    const injectRendering = () => {
      const hasLegacyReactApp = !!document.getElementById('react_app');
      if (hasLegacyReactApp) {
        const userId = get('gon.current_user_id', window);
        const legacyRootEntrypoint = document.getElementById(
          userId ? 'react_app' : 'react-page'
        );

        if (window.location.pathname === '/sign_up') {
          import(/* webpackChunkName: "asyncSignUpApp" */ './SignUpApp').then(
            ({ default: SignUpApp }) => {
              ReactDOM.render(<SignUpApp />, legacyRootEntrypoint);
            }
          );
        } else if (window.location.pathname === '/ats_free') {
          import(/* webpackChunkName: "asyncSignUpApp" */ './SignUpApp').then(
            ({ default: SignUpApp }) => {
              ReactDOM.render(<SignUpApp isATSFree />, legacyRootEntrypoint);
            }
          );
        } else {
          import(/* webpackChunkName: "asyncApp" */ './App').then(
            ({ default: App }) => {
              ReactDOM.render(
                <App routeConfig={legacyRouteConfig} />,
                legacyRootEntrypoint
              );
            }
          );
        }
      }
    };

    const loadedStates = ['complete', 'loaded', 'interactive'];

    if (loadedStates.indexOf(document.readyState) !== -1 && document.body) {
      injectRendering();
    } else {
      window.addEventListener('DOMContentLoaded', injectRendering, false);
    }
  }
};

const setIntlStore = (intlObject: any) => {
  (Intl as any).setStore(
    // @ts-expect-error TS(2769) FIXME: No overload matches this call.
    createStore(state => state, {
      intl: intlObject,
    }),
    { fallbackIntl: AU_TRANSLATION }
  );
};

const startApp = (localeConfig: object) => {
  setIntlStore(localeConfig);
  initApp();
};

const localeCode = getOr(LOCALE_CODE.AU, 'locale', window.gon);

addLocaleData(enLocaleData);

if (localeCode === LOCALE_CODE.AU) startApp(AU_TRANSLATION);
else if (localeCode === LOCALE_CODE.GB)
  startApp(merge(AU_TRANSLATION, GB_TRANSLATION));
else getLocaleConfigFromCdn(localeCode).then(startApp);
