/* This permission will be used in passport layout
  Passport is aka the user scope
  to distinguish with member sidebar permission (member scope)
*/

import { ApiConfigFactory, makeFetchAction } from 'redux-api-call';
import { createSelector } from 'reselect';

import { getMainAppHost } from 'eh-utils/getFrontendEnv';

import { UserSidebarPermissionsApiResponse } from './types';

export const FETCH_USER_SIDEBAR_PERMISSION_TYPE =
  'layout/FETCH_USER_SIDEBAR_PERMISSION_TYPE';

const genFetchParams: ApiConfigFactory = () => ({
  endpoint: `${getMainAppHost()}/api/v3/users/permissions`,
  method: 'GET',
});

const {
  actionCreator: fetchUserSidebarPermissions,
  dataSelector,
  isFetchingSelector: isFetchingUserSidebarPermissionSelector,
  updater: updateUserSidebarPermissionsAC,
} = makeFetchAction<UserSidebarPermissionsApiResponse>(
  FETCH_USER_SIDEBAR_PERMISSION_TYPE,
  genFetchParams
);

export {
  isFetchingUserSidebarPermissionSelector,
  fetchUserSidebarPermissions,
  updateUserSidebarPermissionsAC,
};

export const userSidebarPermissionsSelector = createSelector(
  dataSelector,
  response => response?.data
);

export const accountSecurityAccessSelector = createSelector(
  userSidebarPermissionsSelector,
  permissions => permissions?.access_account_security
);

export const isAdminSelector = createSelector(
  userSidebarPermissionsSelector,
  permissions => permissions?.access_admin
);

export const isB2bSupplierAccountSelector = createSelector(
  userSidebarPermissionsSelector,
  permissions => permissions?.access_b2b_supplier_account
);

export const isCurrentUserBizAccountSelector = createSelector(
  userSidebarPermissionsSelector,
  permissions => permissions?.access_bussiness_account_personnel
);

export const accessUserProfileSelector = createSelector(
  userSidebarPermissionsSelector,
  permissions => permissions?.access_self_profile ?? false
);

export const accessUserAppliedJobsSelector = createSelector(
  userSidebarPermissionsSelector,
  permissions => permissions?.access_applied_jobs ?? false
);

export const viewTranslationSettingSelector = createSelector(
  userSidebarPermissionsSelector,
  permissions => permissions?.i18n_translation_setting?.view ?? false
);

export const accessCareerHubSelector = createSelector(
  userSidebarPermissionsSelector,
  permissions => permissions?.access_career_hub ?? false
);

export const accessTerminatedPortalSelector = createSelector(
  userSidebarPermissionsSelector,
  permissions => permissions?.access_terminated_portal ?? false
);
