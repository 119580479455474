/* The reason why we don't reuse logic inside curreent membership is:
  For now we decide to have user scope, and open space for user that doesn't have any membership
  That's why we have is API to use everything related to user
*/
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// TODO: Add type declaration for hoothoot
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'hoot... Remove this comment to see the full error message
import { deepSet } from 'hoothoot';
import { ApiConfigFactory, makeFetchAction } from 'redux-api-call';
import { createSelector } from 'reselect';

import { getMainAppHost } from '../../eh-utils/getFrontendEnv';
import {
  formatDisplayName,
  formatPreferredName,
} from '../../eh-utils/memberName/format';
import { addItem, updateItem, deleteItem } from './helpers';
import {
  CurrentUserProfileApiResponse,
  PersonalAndContactDetails,
  UpdateFunction,
  UpdateItemToProfileDataActionCreator,
  UpdateUserProfileThunkCreator,
  UserProfile,
} from './types';

export const FETCH_CURRENT_USER_PROFILE_TYPE =
  'layout/FETCH_CURRENT_USER_PROFILE_TYPE';

const genFetchParams: ApiConfigFactory = () => ({
  endpoint: `${getMainAppHost()}/api/v3/users/self_profile`,
  method: 'GET',
});

const {
  actionCreator: fetchCurrentUserProfile,
  dataSelector,
  isFetchingSelector: isFetchingCurrentUserProfileSelector,
  errorSelector: currentUserProfileErrorSelector,
  updater: updateUserProfileAC,
} = makeFetchAction<CurrentUserProfileApiResponse>(
  FETCH_CURRENT_USER_PROFILE_TYPE,
  genFetchParams
);

export {
  isFetchingCurrentUserProfileSelector,
  currentUserProfileErrorSelector,
  fetchCurrentUserProfile,
  updateUserProfileAC,
};

export const currentUserProfileSelector = createSelector(
  dataSelector,
  response => response?.data
);

export const userFirstNameSelector = createSelector(
  currentUserProfileSelector,
  currentUserProfile => currentUserProfile?.first_name
);

export const userLastNameSelector = createSelector(
  currentUserProfileSelector,
  currentUserProfile => currentUserProfile?.last_name
);

export const userPreferredNameSelector = createSelector(
  currentUserProfileSelector,
  currentUserProfile =>
    formatPreferredName({
      firstName: currentUserProfile?.first_name ?? '',
      knownAs: currentUserProfile?.known_as,
    })
);

export const userDisplayNameSelector = createSelector(
  currentUserProfileSelector,
  currentUserProfile =>
    formatDisplayName({
      firstName: currentUserProfile?.first_name ?? '',
      lastName: currentUserProfile?.last_name,
      knownAs: currentUserProfile?.known_as,
    })
);

export const userEmailSelector = createSelector(
  currentUserProfileSelector,
  currentUserProfile => currentUserProfile?.email
);

export const userSessionTokenSelector = createSelector(
  currentUserProfileSelector,
  currentUserProfile => currentUserProfile?.session_token
);

export const userAvatarSelector = createSelector(
  currentUserProfileSelector,
  currentUserProfile => currentUserProfile?.avatar_url
);

export const employmentHistoriesSelector = createSelector(
  currentUserProfileSelector,
  currentUserProfile => currentUserProfile?.employment_histories
);

export const educationHistoriesSelector = createSelector(
  currentUserProfileSelector,
  currentUserProfile => currentUserProfile?.education_histories
);

export const certificationsSelector = createSelector(
  currentUserProfileSelector,
  currentUserProfile => currentUserProfile?.certifications
);

export const skillsSelector = createSelector(
  currentUserProfileSelector,
  currentUserProfile => currentUserProfile?.skills
);

export const resumeSelector = createSelector(
  currentUserProfileSelector,
  currentUserProfile => currentUserProfile?.resume
);

export const profileCompletionPercentageSelector = createSelector(
  currentUserProfileSelector,
  currentUserProfile => currentUserProfile?.profile_completion_percentage ?? 0
);

export const UPDATE_TYPES = {
  CREATE: 'create',
  EDIT: 'edit',
  DELETE: 'delete',
};

export const updateItemToProfileDataAC: UpdateItemToProfileDataActionCreator =
  ({ type, key, item }) =>
  (dispatch, getState) => {
    const state = getState();
    const originData = dataSelector(state);
    const updateFunctions: Record<string, UpdateFunction> = {
      [UPDATE_TYPES.CREATE]: addItem,
      [UPDATE_TYPES.EDIT]: updateItem,
      [UPDATE_TYPES.DELETE]: deleteItem,
    };
    const updateFunction = updateFunctions[type];
    const newData = updateFunction(key)(item)(originData);
    dispatch(updateUserProfileAC(newData));
  };

export const updateUserProfileThunkCreator: UpdateUserProfileThunkCreator =
  ({ type, key, item }) =>
  (dispatch, getState) => {
    const updateSelfProfile = key == null && type === UPDATE_TYPES.EDIT;

    if (updateSelfProfile) {
      const state = getState();
      const originData = dataSelector(state);
      const newData = deepSet('data')(item)(originData);

      dispatch(updateUserProfileAC(newData));
      return;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // TODO: `key` is possibly undefined, need to handle the case where `key == null && type !== UPDATE_TYPES.EDIT`
    // @ts-expect-error TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
    dispatch(updateItemToProfileDataAC({ type, key, item }));
  };

export const personalAndContactDetailsSelector = createSelector(
  currentUserProfileSelector,
  (currentUserProfile?: UserProfile): PersonalAndContactDetails => ({
    id: currentUserProfile?.user_id,
    uuid: currentUserProfile?.user_uuid,
    first_name: currentUserProfile?.first_name,
    last_name: currentUserProfile?.last_name,
    email: currentUserProfile?.email,
    phone_number: currentUserProfile?.phone_number,
    country_code: currentUserProfile?.country_code,
    state_code: currentUserProfile?.state_code,
    city: currentUserProfile?.city,
  })
);

export const userUuidSelector = createSelector(
  currentUserProfileSelector,
  currentUserProfile => currentUserProfile?.user_uuid
);
