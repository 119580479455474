import { RuntimeEnvironmentVariable } from './env';

const ENV: RuntimeEnvironmentVariable = {
  REACT_APP_ACCOUNT_PROVISIONING_API_HOST: '',
  REACT_APP_ACCOUNT_VERIFICATION_SERVICE_API_HOST:
    'https://account-verification-omop.staging.ehrocks.com',
  REACT_APP_ANNOUNCEMENT_API_HOST:
    'https://api.staging.ehrocks.com/announcement',
  REACT_APP_ASSET_REGISTER_INSPECTLET_ID: '950076781',
  REACT_APP_ATS_SERVICE_API_HOST: 'https://ats.staging.ehrocks.com',
  REACT_APP_AUDIT_API_HOST: 'https://api.staging.ehrocks.com/audit-api/api/v2',
  REACT_APP_AUTHORIZATION_SERVICE_HOST: '//authorization.staging.ehrocks.com',
  REACT_APP_BILLING_IMPLEMENTATION_ACCES: '',
  REACT_APP_BILLING_IMPLEMENTATION_ACCESS: 'true',
  REACT_APP_BING_MAPS_SERVICE_API_KEY: '',
  REACT_APP_CAREER_PAGE_HOST: '',
  REACT_APP_CES_SURVEY_DELIGHTED_SURVEY_KEY: 'uUiwAP8DqT6ytGtA',
  REACT_APP_CES_SURVEY_DELIGHTED_SURVEY_LOCALE: 'en-x-employ-11',
  REACT_APP_CHATBOT_FEEDBACK_ENABLED: '',
  REACT_APP_CHATBOT_SIDEPANEL_ENABLED: '',
  REACT_APP_CMS_PDF_EDITOR_INSPECTLET_ID: '',
  REACT_APP_COBRANDING_API_HOST: 'https://co-branding.staging.ehrocks.com',
  REACT_APP_COMMENT_SERVICE_API_HOST: 'https://comment.staging.ehrocks.com',
  REACT_APP_CONSENT_MARKETING_HOST: '',
  REACT_APP_DEMO_ACCOUNT_FLOW_INSPECTLET: '',
  REACT_APP_DEPLOY_SANDBOX_ORG_FEATURE_DATE:
    'Tue Jul 17 2018 13:00:00 GMT+0000 (UTC)',
  REACT_APP_DIGITAL_SIGNATURE_WITH_SIGN_UP: 'true',
  REACT_APP_DISABLED_EMPLOYING_ENTITY_FOR_PAYROLL_FREEMIUM: '',
  REACT_APP_E2P0_MAILCHIMP_API_URL: '',
  REACT_APP_E2P0_MAILCHIMP_LIST_ID: '',
  REACT_APP_EBF_BFF_MOBILE_HOST: '',
  REACT_APP_EBF_EH_FINTECH_HOST: '',
  REACT_APP_EH_ATS_INSPECTLET_ID: '',
  REACT_APP_EH_B2B_INSPECTLET_ID: '',
  REACT_APP_EH_CMS_INSPECTLET_ID: '143686426',
  REACT_APP_EH_HR_INSPECTLET_ID: '1598101060',
  REACT_APP_EH_PAYROLL_GUIDE_INSPECTLET_ID: '',
  REACT_APP_EH_PLAYGROUND_INSPECTLET_ID: '',
  REACT_APP_EMPLOYEE_MILESTONE_SERVICE_API_HOST:
    'https://employee-milestones.staging.ehrocks.com',
  REACT_APP_EMPLOYMENT_SCHEDULES_API_HOST:
    'https://employment-schedules.staging.ehrocks.com',
  REACT_APP_ENABLED_APPCUES_PLATINUM_DASHBOARD: 'true',
  REACT_APP_ENABLED_APPCUES_PREMIUM_DASHBOARD: 'true',
  REACT_APP_ENABLED_APPCUES_SETUP_DASHBOARD: 'true',
  REACT_APP_ENABLED_APPCUES_STANDARD_DASHBOARD: 'true',
  REACT_APP_ENABLED_ATS_CREATE_JOB_NEW_UI: '',
  REACT_APP_ENABLED_CONNECT_CANDIDATE_GUIDE: '',
  REACT_APP_ENABLED_FF_CUSTOM_ATTRIBUTES: '',
  REACT_APP_ENABLED_GT_INTERNAL_NEW_HIRE: '',
  REACT_APP_ENABLED_KP_THEME: 'false',
  REACT_APP_ENABLED_LMS_FEATURES: '',
  REACT_APP_ENABLED_ONE_ON_ONE_FEATURES: '',
  REACT_APP_ENABLED_REBRANDING: '',
  REACT_APP_ENABLED_SCANNING_VIRUS: 'true',
  REACT_APP_ETFND_API_HOST: 'https://etfnd.staging.ehrocks.com',
  REACT_APP_EXPENSE_API_HOST: 'https://expenses.staging.ehrocks.com',
  REACT_APP_EXTERNAL_AUTH_API_HOST: 'https://oauth.staging.employmenthero.com',
  REACT_APP_FEATURE_FLAG_API_HOST:
    'https://api.staging.ehrocks.com/feature-flag',
  REACT_APP_FEATURE_GUIDE_HOST: 'https://feature-guide-api.staging.ehrocks.com',
  REACT_APP_FEEDBACK_INSPECTLET_ID: '',
  REACT_APP_FILE_SERVICE_API_HOST: '//files.staging.ehrocks.com',
  REACT_APP_FILEPICKER_API_KEY: 'ARvL92yHTIu3RCnm6N7cJz',
  REACT_APP_FIREBASE_APIKEY: '',
  REACT_APP_FIREBASE_APP_ID: '',
  REACT_APP_FIREBASE_AUTH_DOMAIN: '',
  REACT_APP_FIREBASE_MESSAGING_SENDER_ID: '',
  REACT_APP_FIREBASE_PROJECT_ID: '',
  REACT_APP_FIREBASE_STORAGE_BUCKET: '',
  REACT_APP_FORM_HOST: 'https://form.staging.ehrocks.com',
  REACT_APP_GA_UUID: '',
  REACT_APP_GOOGLE_API_KEY: 'AIzaSyAFONjoIAeukBOsaIzaWpfxtf1xsW83lfw',
  REACT_APP_HERODOLLAR_PURCHASE_HOST:
    'https://api.staging.ehrocks.com/herodollar-purchase',
  REACT_APP_HERODOLLARS_HOST: 'https://herodollar-service.staging.ehrocks.com',
  REACT_APP_HEROPAY_HOST: 'https://heropay-service.staging.ehrocks.com',
  REACT_APP_INSTAPAY_MARKETING_HOST:
    'https://instapay-marketing-service.staging.ehrocks.com',
  REACT_APP_INTERNAL_INTEGRATIONS_HOST: '',
  REACT_APP_INTERNAL_support01S_HOST: '',
  REACT_APP_JOB_LISTING_SUSHI_TRAIN_ENABLED: '',
  REACT_APP_KEYPAY: '',
  REACT_APP_KP_SYNC_SERVICE_HOST: 'https://sbx-omop-kss.staging.ehrocks.com',
  REACT_APP_LEARNING_REFRESH_TOKEN_REVOKED_NOTICE_ENABLED: '',
  REACT_APP_LEGACY_AUDIT_API_HOST:
    'https://api.staging.ehrocks.com/audit-api/api/v1',
  REACT_APP_LMS_HOST: 'https://lms-service.staging.ehrocks.com',
  REACT_APP_LMS_INSPECTLET_ID: '',
  REACT_APP_LMS_PORTAL_NAME_SUFFIX: '-eh-staging.lms.staging.ehrocks.com',
  REACT_APP_LOCALE_CONFIG_HOST: '',
  REACT_APP_LOCALE_PROJECT_ID: '',
  REACT_APP_MAIL_SERVICE_API_HOST: '//mail-service.staging.ehrocks.com/api',
  REACT_APP_MAIN_APP_HOST: 'https://omop.staging.ehrocks.com',
  REACT_APP_MANUALLY_TRIGGER_ONBOARDING_CHECKLISTS_ENABLED: '',
  REACT_APP_MARKETPLACE_HOST:
    'https://sbx-omop-marketplace.staging.ehrocks.com',
  REACT_APP_MARKETPLACE_INSPECTLET_ID: '143686426',
  REACT_APP_MEETING_MANAGEMENT_SERVICE_API_HOST:
    'https://meeting-management.staging.ehrocks.com',
  REACT_APP_MIXPANEL_TOKEN: '',
  REACT_APP_MOBILE_APP_DYNAMIC_LINK: '',
  REACT_APP_MOCK_DATA: '',
  REACT_APP_MOCK_FOR_CUCUMBER: 'false',
  REACT_APP_NOMINATION_SERVICE_API_HOST:
    'https://nomination.staging.ehrocks.com',
  REACT_APP_NOTIFICATION_GATEWAY_HOST:
    'https://api.staging.ehrocks.com/notification-gateway',
  REACT_APP_ONBOARD_REMOTE_FIELD_ENABLED: 'true',
  REACT_APP_ONEONONE_INSPECTLET_ID: '',
  REACT_APP_PAY_RUN_SERVICE_API_HOST:
    'https://sbx-omop-payrun.staging.ehrocks.com',
  REACT_APP_PAY_SUMMARY_API_HOST: 'https://payment-summary.staging.ehrocks.com',
  REACT_APP_PAYROLL_API_HOST: 'https://payroll.omop.staging.ehrocks.com',
  REACT_APP_PAYROLL_REPORT_SERVICE_API_HOST:
    'https://payroll-report.staging.ehrocks.com',
  REACT_APP_PEO_ACCESS: 'true',
  REACT_APP_PERFORMANCE_EVALUATION_SERVICE_API_HOST:
    'https://performance-evaluation.staging.ehrocks.com',
  REACT_APP_REACTIONS_HOST: '',
  REACT_APP_RECAPTCHA_SITE_KEY: '6LcrvSEUAAAAAPU-MgCstHFXIQerlWNqRcgZNQNY',
  REACT_APP_RECAPTCHA_V2_SITE_KEY: '6Lck8UMUAAAAABpxmLdft-70De0_lT4TUecu-wLq',
  REACT_APP_REPORT_SERVICE_API_HOST: '',
  REACT_APP_ROSTERING_API_HOST:
    'https://rostering-staging-service.staging.ehrocks.com',
  REACT_APP_S3: '',
  REACT_APP_SCAN_VIRUS_URL: '//scan.staging.ehrocks.com/v1/scans',
  REACT_APP_SECURE_SAP_INBOUND_ORIGIN: '',
  REACT_APP_SECURE_SAP_OUTBOUND_ORIGIN: '',
  REACT_APP_SECURITY_GROUPS_MIGRATION_ENABLED: '',
  REACT_APP_SECURITY_PERMISSIONS_INSPECTLET_ID: '',
  REACT_APP_SENTRY_CONFIG:
    'https://a9c6380601804ca995cef545922d3dfa@sentry.staging.ehrocks.com/16',
  REACT_APP_SHOW_CUSTOM_SURVEY_WIDGET: '',
  REACT_APP_SIDEBAR_CACHING_WHITELIST: '',
  REACT_APP_STARTING_GUIDE_PAGE_INSPECTLET: '',
  REACT_APP_STRIPE_PUBLIC_KEY_AU: '',
  REACT_APP_STRIPE_PUBLIC_KEY_GB: '',
  REACT_APP_SUPERFUND_LOOKUP_SERVICE_API_HOST:
    'https://api.staging.ehrocks.com/superfund',
  REACT_APP_SURVEY_HOST: '//api.staging.ehrocks.com/survey-api/api',
  REACT_APP_SURVEYS_WITH_ALLOCATIONS: 'true',
  REACT_APP_SURVEYS_WITH_START_DATE: '',
  REACT_APP_TAX_STSL_TRANSITION_ENABLED: 'true',
  REACT_APP_TOTANGO_SERVICE_ID: 'TEST_service_id',
  REACT_APP_TURN_OFF_INTENTION: 'true',
  REACT_APP_TWOFA_MANDATED: '',
  REACT_APP_URL_SURVEY_SERVICE_API_HOST:
    'https://api.staging.ehrocks.com/survey-api',
  REACT_APP_URL_TRACKING_SERVICE_HOST:
    'https://url-tracking.staging.ehrocks.com',
  REACT_APP_USE_NEW_SETUP_TWOFA_MODAL_UI: '',
  REACT_APP_VITALLY_TOKEN: '',
  REACT_APP_WORKFLOW_WIZARD_API_HOST:
    'https://workflow-wizard.staging.ehrocks.com',
  REACT_APP_PAYROLL_AU_PRIVATE_API_HOST: '',
  REACT_APP_PAYROLL_NZ_PRIVATE_API_HOST: '',
  REACT_APP_PAYROLL_UK_PRIVATE_API_HOST: '',
  REACT_APP_PAYROLL_MY_PRIVATE_API_HOST: '',
  REACT_APP_PAYROLL_SG_PRIVATE_API_HOST: '',
  REACT_APP_WORKFLOW_ENGINE_API_HOST:
    'https://workflow-engine.staging.ehrocks.com',
  REACT_APP_ZENDESK_WIDGET_KEY: '5f6aa5af-fef9-41bc-a234-0ebde2b28c86',
  REACT_APP_ZENDESK_WIDGET_SETUP_MODE_KEY: '',
  REACT_APP_BRAZE_API_KEY: '',
  REACT_APP_LIFECYCLE_SERVICE_HOST:
    'https://lifecycle-service.staging.ehrocks.com',
  REACT_APP_ZENDESK_AI_SESSION_FIELD_ID: '10313062689807',
  REACT_APP_SALARY_GUIDE_SERVICE_HOST:
    'https://salary-guide-external.staging.ehrocks.com',
  REACT_APP_SM_HUB_OCCUPATIONS_LIST_HOST:
    'https://addons-assets.employmenthero.com/smartmatch-hub/occupations.json',
  REACT_APP_SM_HUB_LOCATIONS_LIST_HOST:
    'https://addons-assets.employmenthero.com/smartmatch-hub/location.json',
};

export default ENV;
