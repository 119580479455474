import { makeFetchAction } from 'redux-api-call';
import { createSelector } from 'reselect';
import { PaginatedResponse } from '@ehrocks/api';

import { dataReadyHandler } from 'eh-utils/data';
import { getMainAppHost } from 'eh-utils/getFrontendEnv';

import { currentOrganisationIdSelector } from './layoutInfo';
import { PromotedMenu } from './types/promotedMenus';

export const FETCH_PROMOTED_MENUS_API_CALL_TYPE = 'layout/FETCH_PROMOTED_MENUS';

const {
  actionCreator: fetchPromotedMenusAC,
  dataSelector: promotedMenusDataSelector,
  isFetchingSelector: isFetchingPromotedMenusSelector,
  errorSelector: promotedMenusErrorSelector,
} = makeFetchAction<{ data: PaginatedResponse<PromotedMenu> }>(
  FETCH_PROMOTED_MENUS_API_CALL_TYPE,
  () => ({
    method: 'GET',
    endpoint: createSelector(
      currentOrganisationIdSelector,
      orgId =>
        `${getMainAppHost()}/api/v3/organisations/${orgId}/promoted_menus`
    ),
  })
);

export {
  fetchPromotedMenusAC,
  promotedMenusDataSelector,
  isFetchingPromotedMenusSelector,
  promotedMenusErrorSelector,
};

export const promotedMenusSelector = createSelector(
  promotedMenusDataSelector,
  data => data?.data.items || []
);

export const isPromotedMenusDataReadySelector = createSelector(
  isFetchingPromotedMenusSelector,
  promotedMenusDataSelector,
  promotedMenusErrorSelector,
  (isFetching, data, error) => dataReadyHandler(isFetching, data, error)
);
