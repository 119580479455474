import ReactDOM from 'react-dom';

import {
  getLocaleConfigHost,
  getLocaleProjectId,
} from 'eh-utils/getFrontendEnv';

import FlashMessage from '../modules/spa/beta/components/NotificationManager/Flash/Message';

import AU_TRANSLATION from '../packages/eh-locale/lang/en-AU.json';

const getErrorMessageRoot = (rootId: any) => {
  const existingRoot = document.getElementById(rootId);
  if (existingRoot) return existingRoot;

  const newRoot = document.createElement('div');
  newRoot.setAttribute('id', rootId);
  document.body.prepend(newRoot);

  return document.getElementById(rootId);
};

const renderErrorMessage = () => {
  const rootId = 'locale-error-message';

  ReactDOM.render(
    <FlashMessage
      // @ts-expect-error TS(2322) FIXME: Type '{ content: string; type: string; onRequestRe... Remove this comment to see the full error message
      content="Sorry, we're having trouble getting your preferred language loaded. You can try again later on the Account Settings page."
      type="error"
      // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
      onRequestRemove={() => document.getElementById(rootId).remove()}
    />,
    getErrorMessageRoot(rootId)
  );
};

const fetchWithTimeout = async (resource: any, timeout = 8000) => {
  const controller = new AbortController();

  const id = setTimeout(() => controller.abort(), timeout);

  const response = await fetch(resource, { signal: controller.signal });
  clearTimeout(id);

  return response;
};

const getLocaleConfigFromCdn = (localeCode: any) =>
  fetchWithTimeout(
    `${getLocaleConfigHost()}/api/v1/translations/latest?project_external_id=${getLocaleProjectId()}&locale=${localeCode}&file_name=${localeCode}.json`,
    1000
  )
    .then(res => res.json())
    .then(data => fetchWithTimeout(data.translation_url, 2000))
    .then(res => res.json())
    .catch(() => {
      renderErrorMessage();
      return AU_TRANSLATION;
    });

export { getLocaleConfigFromCdn, renderErrorMessage };
