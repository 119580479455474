import { get } from 'lodash/fp';

import aiEnv from './sbx-ai';
import augustEnv from './sbx-august';
import autopay1Env from './sbx-autopay1';
import autopay2Env from './sbx-autopay2';
import customfieldEnv from './sbx-customfield';
import eliteEnv from './sbx-elite';
import fallbackEnv from './sbx-fallback';
import heliosEnv from './sbx-helios';
import instapayEnv from './sbx-instapay';
import konohaEnv from './sbx-konoha';
import lmsEnv from './sbx-lms';
import marketplaceEnv from './sbx-marketplace';
import mobileEnv from './sbx-mobile';
import omopEnv from './sbx-omop';
import openApiEnv from './sbx-openapi';
import organicEnv from './sbx-organics';
import payrollEnv from './sbx-payroll';
import resellerEnv from './sbx-reseller';
import supportEnv from './sbx-support';
import integrationEnv from './integration';
import support01Env from './support01';
import productionEnv from './production';

import { EnvironmentVariable } from './env';

function getStagingNameFromHostname(hostname: string) {
  const regex = /^([^.]+)\.staging\.ehrocks/;
  const match = hostname.match(regex);
  return match ? match[1] : null;
}

const ENV_MAP = {
  'marketplace-service': marketplaceEnv,
  ai: aiEnv,
  august: augustEnv,
  autopay1: autopay1Env,
  autopay2: autopay2Env,
  customfield: customfieldEnv,
  elite: eliteEnv,
  fallback: fallbackEnv,
  helios: heliosEnv,
  instapay: instapayEnv,
  konoha: konohaEnv,
  lms: lmsEnv,
  mobile: mobileEnv,
  omop: omopEnv,
  openapi: openApiEnv,
  organics: organicEnv,
  payroll: payrollEnv,
  reseller: resellerEnv,
  support: supportEnv,
  integration: integrationEnv,
  support01: support01Env,
  production: productionEnv,
};

export const initializeNonCriticalEnv = () => {
  const buildEnv = process.env;

  try {
    const hostName = window.location.hostname;

    if (hostName?.includes('.staging')) {
      const stagingName = getStagingNameFromHostname(hostName) ?? '';

      return {
        ...buildEnv,
        ...(get(stagingName)(ENV_MAP) || fallbackEnv),
      } as EnvironmentVariable;
    }

    if (/test(?:[0-9]+)?\.keypay\.dev/.test(window.location.host)) {
      return {
        ...buildEnv,
        ...ENV_MAP.omop,
      } as EnvironmentVariable;
    }

    return buildEnv as EnvironmentVariable;
  } catch (e) {
    return buildEnv as EnvironmentVariable;
  }
};

export const initializeCriticalProductionEnv = () => {
  const buildEnv = process.env;

  try {
    const hostName = window.location.hostname;

    if (hostName === 'secure.employmenthero.com') {
      return {
        ...buildEnv,
        ...ENV_MAP.production,
      } as EnvironmentVariable;
    }

    if (hostName === 'secure.support01.employmenthero.com') {
      return {
        ...buildEnv,
        ...ENV_MAP.support01,
      } as EnvironmentVariable;
    }

    if (hostName === 'secure.integration.employmenthero.com') {
      return {
        ...buildEnv,
        ...ENV_MAP.integration,
      } as EnvironmentVariable;
    }

    return buildEnv as EnvironmentVariable;
  } catch (e) {
    return buildEnv as EnvironmentVariable;
  }
};

const initializeEnv = () => {
  switch (process.env.REACT_APP_UNIVERSAL_BUILD_TYPE) {
    case 'production-critical':
      return initializeCriticalProductionEnv();
    case 'non-production':
      return initializeNonCriticalEnv();
    default:
      return process.env as EnvironmentVariable;
  }
};

export const frontendEnv = initializeEnv();
