import { useEffect, Component } from 'react';
import { connect, useSelector } from 'react-redux';
import over from 'lodash/fp/over';
import once from 'lodash/fp/once';
import getOr from 'lodash/fp/getOr';
import anyPass from 'lodash/fp/anyPass';

import { isRailsTestEnv } from 'eh-utils/railsEnvironment';

import { renderRWREntries } from '../../../../../initializer/rwrRegistry';
import {
  errorFetchCurrentMemberSelector,
  errorFetchCurrentOrgSelector,
  fetchCurrentMember,
  updateOrganisationId,
} from '../../components/sessions/state';

const getOrgIdFromGonAndFetchUser = over([
  // @ts-expect-error TS(2345) FIXME: Argument of type 'Gon' is not assignable to parame... Remove this comment to see the full error message
  () => updateOrganisationId(getOr('', 'current_organisation_id')(window.gon)),
  fetchCurrentMember,
]);

const connectToRedux = connect(null, {
  onLoad: getOrgIdFromGonAndFetchUser,
});

const ErrorHandler = () => {
  const critialErrorSelector = anyPass([
    // @ts-expect-error TS(2322) FIXME: Type '(state: DefaultRootState) => GJsonError | un... Remove this comment to see the full error message
    errorFetchCurrentMemberSelector,
    // @ts-expect-error TS(2322) FIXME: Type '(state: DefaultRootState) => GJsonError | un... Remove this comment to see the full error message
    errorFetchCurrentOrgSelector,
  ]);
  const error = useSelector(critialErrorSelector);
  useEffect(() => {
    if (error) {
      if (isRailsTestEnv) return;
      // eslint-disable-next-line immutable/no-mutation
      window.location.pathname = '/500';
    }
  }, [error]);

  return null;
};

class RenderNothingButFetchUser extends Component {
  componentDidMount() {
    if (window.gon.current_organisation_id) {
      // eslint-disable-next-line immutable/no-this
      once((this.props as any).onLoad)();
    }

    renderRWREntries();
  }

  render() {
    return <ErrorHandler />;
  }
}

export default connectToRedux(RenderNothingButFetchUser);
