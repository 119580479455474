const isOnOrganisationLayout = () =>
  window.location.pathname.match(/^\/app\/v2\/organisations/);

const isOnBusinessPortalLayout = () =>
  window.location.pathname.match(/^\/app\/v2\/business_portal/);

const isOnHeroshopSupplierPortalLayout = () =>
  window.location.pathname.match(/^\/app\/v2\/heroshop_supplier_portal/);

const isOnB2bSupplierPortalLayout = () =>
  window.location.pathname.match(/^\/app\/v2\/b2b_supplier_portal/);

const isOnDeveloperPortalLayout = () => {
  const regex = new RegExp(`/app/v2/organisations/([0-9]+)/developer_portal`);
  return window.location.pathname.match(regex);
};

const isOnGlobalTeamsPortalLayout = () =>
  window.location.pathname.match(/^\/app\/v2\/peo_portal/);

const isOnAdminPortal = () => window.location.pathname.match(/^\/admin\//);

export {
  isOnOrganisationLayout,
  isOnBusinessPortalLayout,
  isOnHeroshopSupplierPortalLayout,
  isOnB2bSupplierPortalLayout,
  isOnDeveloperPortalLayout,
  isOnGlobalTeamsPortalLayout,
  isOnAdminPortal,
};
