import flow from 'lodash/fp/flow';
import get from 'lodash/fp/get';
import trim from 'lodash/fp/trim';
import toLower from 'lodash/fp/toLower';

export type Member =
  | {
      first_name?: string;
      last_name?: string;
      known_as?: string | null;
      middle_name?: string | null;
    }
  | {
      firstName?: string;
      lastName?: string;
      knownAs?: string | null;
      middleName?: string | null;
    };

const getAndTrim = <T extends Member>(name: string, member: T): string =>
  flow(get(name), trim)(member);

export const formatFirstName = <T extends Member>(member: T) =>
  getAndTrim('first_name', member) || getAndTrim('firstName', member) || '';

export const formatLastName = <T extends Member>(member: T) =>
  getAndTrim('last_name', member) || getAndTrim('lastName', member) || '';

export const formatMiddleName = <T extends Member>(member: T) =>
  getAndTrim('middle_name', member) || getAndTrim('middleName', member) || '';

export const formatKnownAs = <T extends Member>(member: T) =>
  getAndTrim('known_as', member) || getAndTrim('knownAs', member) || '';

export const formatPreferredName = <T extends Member>(member: T) =>
  formatKnownAs(member) || formatFirstName(member);

export const formatDisplayName = <T extends Member>(member: T) =>
  trim(`${formatPreferredName(member)} ${formatLastName(member)}`);

export const formatFullDisplayName = <T extends Member>(member: T) => {
  const firstName = formatFirstName(member);
  const lastName = formatLastName(member);
  const knownAs = formatKnownAs(member);

  return !!knownAs && toLower(knownAs) !== toLower(firstName)
    ? trim(`${firstName} (${knownAs}) ${lastName}`)
    : trim(`${firstName} ${lastName}`);
};

export const formatLegalName = <T extends Member>(member: T) =>
  trim(`${formatFirstName(member)} ${formatLastName(member)}`);

export const formatFullNameWithMiddleName = <T extends Member>(member: T) => {
  const firstName = formatFirstName(member);
  const middleName = formatMiddleName(member);
  const lastName = formatLastName(member);

  return middleName
    ? trim(`${firstName} ${middleName} ${lastName}`)
    : trim(`${firstName} ${lastName}`);
};
