import * as Sentry from '@sentry/browser';
import { Context, User } from '@sentry/types';

import { getNodeEnv } from './getFrontendEnv';

export interface Tag {
  key: string;
  value: string;
}

interface Extra {
  payload?: Context | null;
  meta?: Context | null;
  debugMeta?: Context | null;
  user?: User;
  tags?: Tag[];
  [key: string]: unknown;
}

const captureError = (error: Error | string, extra?: Extra) => {
  if (getNodeEnv() === 'production') {
    const capturer =
      typeof error === 'string'
        ? Sentry.captureMessage
        : Sentry.captureException;

    if (extra != null) {
      const { payload, meta, debugMeta, user, tags, ...additionalExtra } =
        extra;

      Sentry.withScope(scope => {
        scope.setExtra('extra', JSON.stringify(additionalExtra));
        if (payload != null) scope.setContext('payload', payload);
        if (meta != null) scope.setContext('meta', meta);
        if (debugMeta != null) scope.setContext('debugMeta', debugMeta);
        if (tags?.length) {
          tags.forEach(({ key, value }) => scope.setTag(key, value));
        }
        capturer(error, { user });
      });

      return;
    }

    capturer(error);
    return;
  }

  if (extra != null) {
    // eslint-disable-next-line no-console
    console.error(error, extra);
    return;
  }

  // eslint-disable-next-line no-console
  console.error(error);
};

export default captureError;
