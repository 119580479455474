import isNil from 'lodash/fp/isNil';
import omitBy from 'lodash/fp/omitBy';
import isEmpty from 'lodash/fp/isEmpty';
import { stringify, IStringifyOptions as StringifyOptionsType } from 'qs';

export type GenerateGetURLType = (
  href: string,
  params?: Record<string, unknown>,
  stringifyOption?: StringifyOptionsType
) => string;

const generateGetURL: GenerateGetURLType = (
  href,
  params,
  stringifyOption = { arrayFormat: 'brackets' }
): string => {
  const normalizedParamsFunction = omitBy(isNil);
  const normalizedParams = normalizedParamsFunction(params);
  return isEmpty(normalizedParams)
    ? href
    : `${href}${href.includes('?') ? '&' : '?'}${stringify(
        normalizedParams,
        stringifyOption
      )}`;
};

export default generateGetURL;
