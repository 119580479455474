/* global gon */
import { connect } from 'react-redux';
import { compose, lifecycle } from 'recompose';
import { push } from 'connected-react-router';

import { addNotification } from '../../components/NotificationManager/notificationManager.state';

// @ts-expect-error TS(2339) FIXME: Property 'notificationMessage' does not exist on t... Remove this comment to see the full error message
const connectToRedux = connect(null, (dispatch, { notificationMessage }) => ({
  navigateToDashboard: (orgId: any) => {
    dispatch(push(`/app/v2/organisations/${orgId}/dashboard`));
    if (notificationMessage) {
      dispatch(
        addNotification({ content: notificationMessage, type: 'error' })
      );
    }
  },
  navigateTo404: () => dispatch(push('/app/v2/404?m=NO_ORG')),
}));

const setLifecycle = lifecycle({
  componentDidMount() {
    // @ts-expect-error TS(2304) FIXME: Cannot find name 'gon'.
    const organisationId = gon.current_organisation_id;
    if (organisationId) {
      // eslint-disable-next-line immutable/no-this
      (this as any).props.navigateToDashboard(organisationId);
    } else {
      // eslint-disable-next-line immutable/no-this
      (this as any).props.navigateTo404();
    }
  },
});

const enhance = compose(connectToRedux, setLifecycle);

// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
const RedirectToDefaultOrg = ({ notificationMessage }: any) => <div />;

export default enhance(RedirectToDefaultOrg);
