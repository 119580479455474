import {
  ForgotPassword,
  ResetPasswordPage,
} from '../../../authen/asyncBundles';
import { SignIn } from '../../../signIn/asyncBundles';
import RenderNothingButFetchUser from './scope/RenderNothingButFetchUser';
import RedirectToDefaultOrg from './scope/RedirectToDefaultOrg';

const legacyRouteConfig = {
  ROOT: {
    path: '/',
    exact: true,
    component: RedirectToDefaultOrg,
    // not a UI route, skip tracking
    skipTracking: true,
  },
  SIGN_IN: {
    path: '/users/sign_in',
    exact: true,
    component: SignIn,
    skipTracking: true,
  },
  FORGOT_PASSWORD: {
    path: '/users/password/new',
    exact: true,
    component: ForgotPassword,
    // rails tracked already, skip tracking
    skipTracking: true,
  },
  CHANGE_PASSWORD: {
    path: '/users/password/edit',
    exact: true,
    component: ResetPasswordPage,
    // rails tracked already, skip tracking
    skipTracking: true,
  },
  ALL: {
    path: '*',
    component: RenderNothingButFetchUser,
    // those just render RWR, rails tracked already, skip tracking
    skipTracking: true,
  },
};

export default legacyRouteConfig;
