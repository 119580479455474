import { Button } from '@hero-design/react';
import compose from 'recompose/compose';
import defaultProps from 'recompose/defaultProps';
import lifecycle from 'recompose/lifecycle';
import styled, { css } from 'styled-components';

const AlertWrapper = styled.div`
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  right: 0;
  padding: 20px 2%;
  height: 60px;
  text-align: center;
  font-size: 1.2em;
  width: 100%;
  border-radius: 0;
  text-align: center;
  height: auto;

  ${({
    // @ts-expect-error TS(2339) FIXME: Property 'type' does not exist on type 'Pick<Detai... Remove this comment to see the full error message
    type,
  }) => {
    switch (type) {
      // Need to replace by HD color
      // eslint-disable-next-line @ehrocks/eh-linter/avoid-using-absolute-color
      case 'black':
        // Need to replace by HD color
        // eslint-disable-next-line @ehrocks/eh-linter/avoid-using-absolute-color
        return css`
          background-color: #444;
          color: #fff;
        `;
      // Need to replace by HD color
      // eslint-disable-next-line @ehrocks/eh-linter/avoid-using-absolute-color
      case 'white':
        // Need to replace by HD color
        // eslint-disable-next-line @ehrocks/eh-linter/avoid-using-absolute-color
        return css`
          background-color: #fff;
          color: #444;
        `;
      default:
        return css``;
    }
  }};
`;

const AlertContent = styled.div`
  margin-right: auto;
  padding-bottom: 0;
`;

const enhance = compose(
  defaultProps({
    delay: 5000,
    autoDismiss: true,
  }),
  lifecycle({
    componentDidMount() {
      // eslint-disable-next-line immutable/no-this
      if ((this as any).props.autoDismiss) {
        // eslint-disable-next-line immutable/no-this
        setTimeout(() => {
          // eslint-disable-next-line immutable/no-this
          (this as any).props.onRequestRemove((this as any).props.id);
        }, (this as any).props.delay); // eslint-disable-line immutable/no-this
        // eslint-disable-line immutable/no-this
      }
    },
  })
);

export const RemoveIcon = ({ id, onRequestRemove }: any) => (
  <Button.Icon
    icon="cancel"
    onClick={() => onRequestRemove(id)}
    sx={{ position: 'absolute', top: 15, right: 15 }}
  />
);

const PureMessage = ({
  title = '',
  content,
  type,
  autoDismiss = true,
  ...other
}: any) => (
  <AlertWrapper
    className={`alert alert-centered fade in alert-${type} ${
      type === 'error' ? 'alert-danger' : ''
    }`.trim()}
    // @ts-expect-error TS(2769) FIXME: No overload matches this call.
    type={type}
  >
    <AlertContent>
      <div>{title}</div>
      <div>{content}</div>
    </AlertContent>
    {!autoDismiss && <RemoveIcon {...other} />}
  </AlertWrapper>
);

export default enhance(PureMessage);
