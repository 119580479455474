// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

const loadIntlAndLocaleData = async () => {
  if (!global.Intl) {
    await import('intl');
    await import('intl/locale-data/jsonp/en.js');
  }
};

loadIntlAndLocaleData();

export {};
