import isFunction from 'lodash/fp/isFunction';
import { makeFetchAction } from 'redux-api-call';
import { createSelector } from 'reselect';
import map from 'lodash/fp/map';
import isEqual from 'lodash/fp/isEqual';
import { ThunkAction } from 'redux-thunk';
import { AnyAction } from 'redux';
// hoothoot does not have type definitions for this yet
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'hoot... Remove this comment to see the full error message
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
import { deepUpdate } from 'hoothoot';

import { getMainAppHost } from 'eh-utils/getFrontendEnv';

import { currentOrganisationIdSelector } from './layoutInfo';
import {
  CounterFeatureKey,
  GroupCounterFeatureKey,
  SubCounterFeatureKey,
  SidebarCounter,
  SidebarGroupCounter,
  SidebarCounterList,
  SidebarCountersData,
} from './types/sidebarCounter';

export const FETCH_SIDEBAR_COUNTER_API_TYPE = 'layout/FETCH_SIDEBAR_COUNTER';

export const genFetchParams = () => ({
  endpoint: createSelector(
    currentOrganisationIdSelector,
    id => `${getMainAppHost()}/api/v3/organisations/${id}/sidebar_counters`
  ),
});

export const {
  actionCreator: fetchSidebarCounterAC,
  dataSelector: rawDataSelector,
  errorSelector: fetchSidebarCounterErrorSelector,
  isFetchingSelector: isFetchingSidebarCounterSelector,
  updater: sidebarCounterUpdaterAC,
} = makeFetchAction<SidebarCountersData>(
  FETCH_SIDEBAR_COUNTER_API_TYPE,
  genFetchParams
);

export const sidebarCounterDataSelector = createSelector(
  rawDataSelector,
  data => data?.data?.items
);

type UpdateSidebarCounterACType = <
  T extends CounterFeatureKey,
  V extends GroupCounterFeatureKey = GroupCounterFeatureKey
>(props: {
  group?: V;
  mainModule: T;
  subModule: SubCounterFeatureKey<T>;
  calculateCounter?: (value: number) => number;
  subtractValue?: number;
}) => ThunkAction<
  void,
  Parameters<typeof sidebarCounterDataSelector>[0],
  unknown,
  AnyAction
>;

export const generateNewCounterDataFromOldCounterData =
  ({
    group,
    mainModule,
    subModule,
    calculateCounter,
    subtractValue = 1,
  }: Parameters<UpdateSidebarCounterACType>[0]) =>
  (oldCounterData: SidebarCounterList) => {
    const generateNewCounterItem = (
      oldCounterItem: SidebarCounter<SubCounterFeatureKey<CounterFeatureKey>>
    ) => ({
      ...oldCounterItem,
      value: isFunction(calculateCounter)
        ? calculateCounter(oldCounterItem?.value)
        : oldCounterItem?.value - subtractValue,
      items: map<
        SidebarCounter<SubCounterFeatureKey<CounterFeatureKey>>,
        SidebarCounter<SubCounterFeatureKey<CounterFeatureKey>>
      >(children =>
        isEqual(children.name, subModule)
          ? {
              ...children,
              value: isFunction(calculateCounter)
                ? calculateCounter(children.value)
                : children.value - subtractValue,
            }
          : children
      )(oldCounterItem?.items ?? []),
    });

    const generateNewCounterGroupValue = (
      oldCounterItem: SidebarGroupCounter<GroupCounterFeatureKey>
    ) => ({
      ...oldCounterItem,
      value: isFunction(calculateCounter)
        ? calculateCounter(oldCounterItem?.value ?? 0)
        : (oldCounterItem?.value ?? 0) - subtractValue,
    });

    const newCounterData: SidebarCounterList = deepUpdate(
      group
        ? `[{name=${group}}].items.[{name=${mainModule}}]`
        : `[{name=${mainModule}}]`,
      generateNewCounterItem,
      oldCounterData
    );

    return group
      ? deepUpdate(
          `[{name=${group}}]`,
          generateNewCounterGroupValue,
          newCounterData
        )
      : newCounterData;
  };

export const updateSidebarCounterAC: UpdateSidebarCounterACType =
  props => (dispatch, getState) => {
    const state = getState();
    const sidebarCounterData = sidebarCounterDataSelector(state);

    if (!sidebarCounterData) return;

    const newCounterData =
      generateNewCounterDataFromOldCounterData(props)(sidebarCounterData);

    dispatch(
      sidebarCounterUpdaterAC({
        data: {
          kind: 'sidebar_permission_counter',
          items: newCounterData,
        },
      })
    );
  };
