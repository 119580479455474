import eq from 'lodash/fp/eq';
import flow from 'lodash/fp/flow';
import path from 'lodash/fp/path';
import isNil from 'lodash/fp/isNil';
import negate from 'lodash/fp/negate';
import isMatch from 'lodash/fp/isMatch';
import { makeFetchAction } from 'redux-api-call';
import { createSelector } from 'reselect';

import { dataReadyHandler } from 'eh-utils/data';
import { getMainAppHost } from 'eh-utils/getFrontendEnv';

import { currentOrganisationIdSelector } from './layoutInfo';
import {
  SidebarPermissionV2,
  FeatureKey,
  SubFeatureKey,
  AdditionalContext,
} from './types/sidebarPermissionV2';

export const FETCH_SIDEBAR_PERMISSIONS_V2_API_CALL_TYPE =
  'layout/FETCH_SIDEBAR_PERMISSIONS_V2';

const {
  actionCreator: fetchSidebarPermissionsV2AC,
  dataSelector,
  isFetchingSelector: isFetchingPermissionV2Selector,
  updater: updateSidebarPermissionsV2AC,
  errorSelector: errorSidebarPermissionsV2Selector,
} = makeFetchAction<SidebarPermissionV2>(
  FETCH_SIDEBAR_PERMISSIONS_V2_API_CALL_TYPE,
  () => ({
    method: 'GET',
    endpoint: createSelector(
      currentOrganisationIdSelector,
      id => `${getMainAppHost()}/api/v2/organisations/${id}/sidebar_permissions`
    ),
  })
);

export {
  fetchSidebarPermissionsV2AC,
  isFetchingPermissionV2Selector,
  updateSidebarPermissionsV2AC,
  errorSidebarPermissionsV2Selector,
};

export const sidebarPermissionsV2Selector = createSelector(
  dataSelector,
  data => data?.data
);

export const isSidebarPermissionsV2DataReadySelector = createSelector(
  isFetchingPermissionV2Selector,
  dataSelector,
  errorSidebarPermissionsV2Selector,
  (isFetching, data, error) => dataReadyHandler(isFetching, data, error)
);

const menuPath = <T extends FeatureKey>(
  menuKey: T,
  subMenuKey?: SubFeatureKey<T>
) => (subMenuKey ? `${menuKey}.child_items.${subMenuKey}` : menuKey);

export const getMenuPermission = <T extends FeatureKey>({
  menuKey,
  subMenuKey,
}: {
  menuKey: T;
  subMenuKey?: SubFeatureKey<T>;
}) => flow(path(`${menuPath(menuKey, subMenuKey)}.available`), eq(true));

export const getMenuAdditionalContext = <T extends FeatureKey>({
  menuKey,
  subMenuKey,
  contextKey,
}: {
  menuKey: T;
  subMenuKey?: SubFeatureKey<T>;
  contextKey?: string;
}) => path(`${menuPath(menuKey, subMenuKey)}.additional_context.${contextKey}`);

export const checkMenuAdditionalContext = <T extends FeatureKey>({
  menuKey,
  subMenuKey,
  context,
}: {
  menuKey: T;
  subMenuKey?: SubFeatureKey<T>;
  context: AdditionalContext;
}) =>
  flow(
    path(`${menuPath(menuKey, subMenuKey)}.additional_context`),
    isMatch(context)
  );

export const checkHasSubMenus = <T extends FeatureKey>({
  menuKey,
}: {
  menuKey: T;
}) => flow(path(`${menuKey}.child_items`), negate(isNil));

export const getSubMenu = <T extends FeatureKey>({
  menuKey,
  subMenuKey,
}: {
  menuKey: T;
  subMenuKey?: SubFeatureKey<T>;
}) => path(menuPath(menuKey, subMenuKey));
