/* eslint-disable immutable/no-this, immutable/no-let, immutable/no-mutation */
(() => {
  if (!HTMLCanvasElement.prototype.toBlob) {
    Object.defineProperty(HTMLCanvasElement.prototype, 'toBlob', {
      value(callback: any, type: any, quality: any) {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const canvas = this;
        setTimeout(() => {
          const binStr = atob(canvas.toDataURL(type, quality).split(',')[1]);
          const len = binStr.length;
          const arr = new Uint8Array(len);

          for (let i = 0; i < len; i += 1) {
            arr[i] = binStr.charCodeAt(i);
          }

          callback(new Blob([arr], { type: type || 'image/png' }));
        });
      },
    });
  }
})();

export {};
