import get from 'lodash/fp/get';
import isNil from 'lodash/fp/isNil';
import omitBy from 'lodash/fp/omitBy';
import flow from 'lodash/fp/flow';
import join from 'lodash/fp/join';
import path from 'lodash/fp/path';
import compact from 'lodash/fp/compact';
import { createSelector } from 'reselect';
import { makeFetchAction } from 'redux-api-call';

import generateGetURL from 'eh-utils/urlGenerator';
import { getMainAppHost } from 'eh-utils/getFrontendEnv';

export const CALL_API_TYPE = 'shared/GET_USER_SESSION';

export const genFetchParams = (params: any) => ({
  endpoint: generateGetURL(
    `${getMainAppHost()}/api/v2/sessions`,
    omitBy(isNil, params)
  ),

  method: 'GET',
});

const {
  actionCreator: fetchAC,
  dataSelector: rawDataSelector,
  errorSelector: rawErrorSelector,
  isFetchingSelector,
  // @ts-expect-error TS(2345) FIXME: Argument of type '(params: any) => { endpoint: str... Remove this comment to see the full error message
} = makeFetchAction(CALL_API_TYPE, genFetchParams);

const userDataSelector = createSelector(rawDataSelector, path('user'));

export { fetchAC, userDataSelector, rawErrorSelector, isFetchingSelector };

export const userInfoDataSelector = createSelector(
  userDataSelector,
  path('user_info')
);

export const whitelistedSelector = createSelector(
  userDataSelector,
  path('whitelisted')
);

export const userInfoIdSelector = createSelector(
  userInfoDataSelector,
  path('id')
);

export const userEmailSelector = createSelector(
  userDataSelector,
  path('email')
);

export const userSessionTokenSelector = createSelector(
  userDataSelector,
  path('session_token')
);

export const userInfoLastNameSelector = createSelector(
  userInfoDataSelector,
  path('last_name')
);

export const userInfoFirstNameSelector = createSelector(
  userInfoDataSelector,
  path('first_name')
);

export const hasAccessToB2bSupplierPortalSelector = createSelector(
  userDataSelector,
  get('access_supplier_portal')
);

export const hasAccessToGlobalTeamPortalSelector = createSelector(
  userDataSelector,
  get('access_global_teams_portal')
);

export const fullName = (...nameParts: any[]) =>
  flow([compact, join(' ')])(nameParts);

export const userInfoFullNameSelector = createSelector(
  userInfoFirstNameSelector,
  userInfoLastNameSelector,
  fullName
);

export const businessAccountsSelector = createSelector(
  userDataSelector,
  path('business_accounts')
);

export const userUuidSelector = createSelector(userDataSelector, path('uuid'));

export const defaultBusinessAccountSelector = createSelector(
  userDataSelector,
  path('business_accounts[0]')
);
