/* eslint-disable immutable/no-this */
import { Component, ErrorInfo, ReactNode, ReactElement } from 'react';

import ErrorPage from '../../../modules/shared/components/ErrorPage';
import captureError, { Tag } from '../captureError';

interface Props {
  children: ReactNode;
  fallbackComponent?: ReactElement;
  tags?: Tag[];
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    // eslint-disable-next-line immutable/no-mutation, immutable/no-this
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  // eslint-disable-next-line class-methods-use-this
  componentDidCatch(error: Error, info: ErrorInfo) {
    captureError(error, { info, tags: this.props.tags });
  }

  render() {
    if (this.state.hasError) {
      if (this.props.fallbackComponent != null) {
        return this.props.fallbackComponent;
      }
      return <ErrorPage />;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
