import { createAction, handleAction } from 'redux-actions';

const identity = (i: any) => i;
const takePayload = (state: any, action: any) => action.payload;

const makeSetterAction = (actionName: any, mapPayload = identity) => {
  const actionCreator = createAction(actionName, mapPayload);
  // @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 0.
  const guessingDefaultValue = mapPayload();
  const defaultValue =
    typeof guessingDefaultValue !== 'undefined' ? guessingDefaultValue : null;
  const reducer = handleAction(actionName, takePayload, defaultValue);

  return { actionCreator, reducer };
};

export default makeSetterAction;
