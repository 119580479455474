import find from 'lodash/fp/find';
import { combineReducers } from 'redux';
import uniqueId from 'lodash/fp/uniqueId';
import get from 'lodash/fp/get';
import filter from 'lodash/fp/filter';
import always from 'lodash/fp/always';
import { createAction, handleActions, handleAction } from 'redux-actions';

// action types
export const ADD_NOTIFICATION = 'notifications/ADD';
export const REMOVE_NOTIFICATION = 'notifications/REMOVE';
export const FORCE_SHOW_NOTIFICATION = 'notifications/FORCE_SHOW';
export const UPDATE_NOTIFICATION = 'notifications/UPDATE';
export const UPSERT_NOTIFICATION = 'notifications/UPSERT';

// action creators
export const addNotification = createAction(ADD_NOTIFICATION);
export const removeNotification = createAction(REMOVE_NOTIFICATION);
export const updateNotification = createAction(UPDATE_NOTIFICATION);
export const upsertNotification = createAction(UPSERT_NOTIFICATION);
export const forceAddNotification = always({
  type: FORCE_SHOW_NOTIFICATION,
  payload: true,
});

export const forceShowNotification = (props: any) => (dispatch: any) => {
  dispatch(forceAddNotification());
  dispatch(addNotification(props));
};

export const notificationsSelector = get('ehNotifications.items');
export const forceShowNotificationSelector = get(
  'ehNotifications.forcedShowNotification'
);

// reducer
// @ts-expect-error TS(2769) FIXME: No overload matches this call.
export const notificationError = handleActions(
  {
    [ADD_NOTIFICATION]: (state, { payload }) => [
      ...state,
      { id: uniqueId('notification_'), ...payload },
    ],

    [REMOVE_NOTIFICATION]: (state, { payload }) => {
      const nextState = filter(
        notification => (notification as any).id !== payload,
        state
      );
      if (nextState.length === state.length) {
        return state;
      }

      return nextState;
    },

    [UPDATE_NOTIFICATION]: (state, { payload }) => {
      if (!(payload as any).id) return state;
      return state.map(item => {
        if ((item as any).id !== (payload as any).id) return item;
        return Object.assign(item, payload);
      });
    },

    [UPSERT_NOTIFICATION]: (state, { payload }) => {
      if (find({ id: (payload as any).id })(state))
        return state.map(item => {
          if ((item as any).id !== (payload as any).id) return item;
          return Object.assign(item, payload);
        });

      return [...state, { id: uniqueId('notification_'), ...payload }];
    },
  },
  []
);

const forcedShowNotification = handleAction(
  FORCE_SHOW_NOTIFICATION,
  // @ts-expect-error TS(2769) FIXME: No overload matches this call.
  (state, { payload }) => payload,
  false
);

const reducers = combineReducers({
  items: notificationError,
  forcedShowNotification,
});

export default { ehNotifications: reducers };
