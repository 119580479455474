import { ComponentType } from 'react';
import { Spinner } from '@hero-design/react';
import ReactLoadable, { LoadingComponentProps } from 'react-loadable';

import ErrorBoundary from './ErrorBoundary';

const DEFAULT_EXPORT = 'default';

type Exports = Record<string, ComponentType<unknown>>;

const Loadable = <Props extends Record<string, unknown>>(
  loader: () => Promise<any>,
  exportedName: string = DEFAULT_EXPORT,
  loading: ComponentType<LoadingComponentProps> = Spinner
) =>
  ReactLoadable<Props, Exports>({
    loader,
    loading,
    render: (loaded, props) => {
      const LoadedComponent = loaded[exportedName];

      if (LoadedComponent && DEFAULT_EXPORT !== exportedName) {
        // eslint-disable-next-line immutable/no-mutation
        LoadedComponent.displayName = exportedName;
      }

      return (
        <ErrorBoundary>
          <LoadedComponent {...props} />
        </ErrorBoundary>
      );
    },
  });

export default Loadable;
