/* eslint-disable immutable/no-mutation */
import isEmpty from 'lodash/fp/isEmpty';
import forEach from 'lodash/fp/forEach';

import { sendBrowserAgnosticEvent } from '../agnosticEvents/utils';

const rwrEntries: any = [];

const callAction = (action: any, ...actionParams: any[]) => {
  const rwrFn = (window as any).RWR[action];
  return rwrFn(...actionParams);
};

const registerRWREntry = (action: any, ...actionParams: any[]) => {
  rwrEntries.push({ action, actionParams });
};

const callRWR = (...args: any[]) => {
  const fn = window.RWR ? callAction : registerRWREntry;
  // @ts-expect-error TS(2556) FIXME: A spread argument must either have a tuple type or... Remove this comment to see the full error message
  return fn(...args);
};

const showMainContent = () => {
  const mainContent = document.getElementById('wraper-content');
  const spinner = document.getElementById('wrapper-loading-spinner');
  if (spinner && mainContent) {
    spinner.style.display = 'none';
    mainContent.style.display = 'block';
  }
};

const renderRWREntries = async () => {
  if (!isEmpty(rwrEntries)) {
    const { rwrRegister } = await import(
      /* webpackChunkName: "async-rwr" */ '../modules/spa/rwr-register'
    );
    await import(
      /* webpackChunkName: 'spa.beta' */ '../modules/spa/beta/components/Navigation/NavigationWithAllItems'
    );

    const { queryClient } = await import(
      /* webpackChunkName: "asyncApp" */ '../App'
    );
    const store = await import(
      /* webpackChunkName: "asyncStore" */ '../App/store'
    );
    rwrRegister(store.default, queryClient);
    showMainContent();
    forEach(({ action, actionParams }) => {
      callAction(action, ...actionParams);
    }, rwrEntries);
  }
};

window.callRWR = callRWR;

sendBrowserAgnosticEvent(window, 'initializedRWR');

export { renderRWREntries };
