import { Intl } from 'redux-intl';
import { Button, Result } from '@hero-design/react';

// @ts-expect-error TS(2554) FIXME: Expected 0 arguments, but got 1.
const refreshCurrentPage = () => window.location.reload(true);

const ErrorPage = ({
  errorTitle = Intl.formatMessage({ id: 'globalErrorPage.defaultTitle' }),
  errorDesc = Intl.formatMessage({ id: 'globalErrorPage.defaultSubTitle' }),
  errorStatus = '404',
  buttonText = Intl.formatMessage({ id: 'button.tryAgain' }),
  onRefresh = refreshCurrentPage,
}) => (
  <Result
    title={errorTitle}
    subTitle={errorDesc}
    // @ts-expect-error TS(2322) FIXME: Type 'string' is not assignable to type '"404" | "... Remove this comment to see the full error message
    status={errorStatus}
    extra={<Button text={buttonText} onClick={onRefresh} />}
  />
);

export default ErrorPage;
